<div class="item-list">
  <div *ngFor="let item of items" class="item" (click)="selectItem(item)">
    <div class="item-details">
      <span class="item-label">{{
        translate ? Jaf.translate(item[displayField]) : item[displayField]
      }}</span>
    </div>
    <tabler-icon
      *ngIf="selected === item[valueField]"
      icon="check"
      size="md"
      color="success"
    ></tabler-icon>
  </div>
</div>
