import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsInstallation extends JafConcept {
  public primary = 'INS_ID';

  protected name = 'nf_gds_installation';

  protected class = 'C_Gds_Installation';

  protected rowClass = 'CGdsInstallationRow';

  protected trigramme = 'INS';

  public _flagUniqueRowset = true;
}
