import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { MissionLocation } from '../../mission/interfaces/mission';

export class CGeoLieuRow extends JafRow {

  static LIE_UNKNOWN_DESTINATION = '1';

  @WayChamp({
    label: '%LAB_GEO_LIEU_ID%',
    class: 'Id',
  })
  public LIE_ID;

  @WayChamp({
    label: '%LAB_GEO_LIEU_TLI_ID%',
    class: 'Liaison',
    // liaison: 'C_Geo_TypeLieu',
  })
  public LIE_TLI_ID;

  @WayChamp({
    label: '%LAB_GEO_LIEU_LIBELLE%',
    class: 'Libelle',
  })
  public LIE_LIBELLE;

  @WayChamp({
    label: '%LAB_GEO_LIEU_FORMATED%',
    class: 'Texte',
  })
  public LIE_FORMATED;

  @WayChamp({
    label: '%LAB_GEO_LIEU_INFO%',
    class: 'Texte',
  })
  public LIE_INFO;

  @WayChamp({
    label: '%LAB_GEO_LIEU_VILLE%',
    class: 'Ville',
  })
  public LIE_VILLE;

  @WayChamp({
    label: '%LAB_GEO_LIEU_CP%',
    class: 'Codepostal',
  })
  public LIE_CP;

  @WayChamp({
    label: '%LAB_GEO_LIEU_PAY_ID%',
    class: 'Liaison',
    // liaison: 'C_Geo_Pays',
  })
  public LIE_PAY_ID;

  @WayChamp({
    label: '%LAB_GEO_LIEU_LAT%',
    class: 'Quantite',
  })
  public LIE_LAT;

  @WayChamp({
    label: '%LAB_GEO_LIEU_LNG%',
    class: 'Quantite',
  })
  public LIE_LNG;

  @WayChamp({
    label: '%LAB_GEO_LIEU_REF_EXTERNE%',
    class: 'Texte',
  })
  public LIE_REF_EXTERNE;

  @WayChamp({
    label: '%LAB_GEO_LIEU_ETAT%',
    class: 'Etat',
  })
  public LIE_ETAT;

  @WayChamp({
    label: '%LAB_GEO_LIEU_TIMEZONE%',
    class: 'Texte',
  })
  public LIE_TIMEZONE;

  get infoLocation(): MissionLocation {
    let title                = this.LIE_LIBELLE;
    const [address, zipCity] = this.LIE_FORMATED ? this.LIE_FORMATED.split(',') : ['', ''];
    if (this.LIE_LIBELLE === this.LIE_FORMATED) {
      title = address;
    }
    if (!title) {
      title = address;
    }

    const infoLocation: MissionLocation = {
      title,
      subtitle         : address,
      addressCommentary: this.LIE_INFO,
      zipCity,
      zone             : this.LIE_VILLE,
    };

    if (this.LIE_FORMATED) {
      infoLocation.formated = this.LIE_FORMATED;
    }

    if (infoLocation.title === infoLocation.subtitle) {
      infoLocation.subtitle = null;
    }
    if (+this.LIE_TLI_ID === 4) {
      infoLocation.title = infoLocation.title.replace('%', '');
      infoLocation.title = infoLocation.title.replace('%', '');
    }
    return infoLocation;
  }

  get label() {
    return `${this.LIE_LIBELLE} ${this.LIE_FORMATED}`;
  }
}
