import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGdsNiveauPrestigeRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_NIVEAUPRESTIGE_ID%',
    class: 'Id',
  })
  public NPR_ID;

  @WayChamp({
    label: '%LAB_GDS_NIVEAUPRESTIGE_LIBELLE%',
    class: 'Libelle',
  })
  public NPR_LIBELLE;
}