<div
  class="ticket"
  *ngIf="mission"
  [ngClass]="{ 'action-view': isActionView }"
  (click)="navigateToDetails()"
>
  <div class="separator"></div>
  <div class="ticket-content-wrapper">
    <!-- TICKET TOP -->
    <div class="ticket-top flex justify-between" [ngClass]="{ 'items-center': !isActionView }">
      <div class="mission-details flex flex-col">
        <span class="mission-date font-semibold text-lg">{{
          Jaf.formatShortDate(mission.startTime)
        }}</span>
        <span class="ticket-subtitle font-medium">{{
          mission?.MIS_TSE_ID?.TSE_LIBELLE || 'Transfert' | jafTranslate : null : Jaf.LAN_CODE
        }}</span>
      </div>
      <ng-container *ngIf="isActionView; else missionDateBadge">
        <div class="status-badge">
          <status-badge [mission]="mission"></status-badge>
        </div>
      </ng-container>
      <ng-template #missionDateBadge>
        <div
          class="mission-date-badge font-semibold"
          *ngIf="timeBeforeMission || daysBeforeMission"
        >
          <ng-container *ngIf="timeBeforeMission; else daysTemplate">
            {{ 'Dans' | translate }} {{ timeBeforeMission.value }}
            {{ timeBeforeMission.unit | translate }}{{ timeBeforeMission.value > 1 ? 's' : '' }}
          </ng-container>
          <ng-template #daysTemplate>
            <ng-container *ngIf="daysBeforeMission">
              {{ 'Dans' | translate }} {{ daysBeforeMission }} {{ 'jour' | translate
              }}{{ daysBeforeMission > 1 ? 's' : '' }}
            </ng-container>
          </ng-template>
        </div>
      </ng-template>
    </div>
    <!-- TICKET BOTTOM -->
    <div class="ticket-bottom flex justify-between items-center">
      <div class="flex ticket-location" *ngIf="missionStartStep.EPR_LIE_ID as location">
        <tabler-icon
          [icon]="location.icon"
          size="sm"
          [color]="isActionView ? 'd2' : ''"
        ></tabler-icon>
        <div class="flex flex-col">
          <span class="location-label font-bold text-md">{{
            location.LIE_LIBELLE || ('Lieu privé' | translate)
          }}</span>
          <span class="ticket-subtitle">{{
            location.LIE_FORMATED || ('Addresse inconnue' | translate)
          }}</span>
        </div>
      </div>
      <div class="flex flex-col gap-xs">
        <span class="text-md font-medium mission-start-time">{{
          missionStartStep.startTime | date : 'shortTime' : null : Jaf.LAN_CODE
        }}</span>
        <div
          *ngIf="missionDurationInHours as duration"
          class="flex items-center duration-container"
        >
          <tabler-icon
            icon="clock-hour-7"
            [color]="isActionView ? 'd1' : 'm2'"
            size="xxs"
          ></tabler-icon>
          <span class="font-medium">{{ duration }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
