<div>
  <ion-item lines="none">
    <ion-input
      [type]="type"
      [id]="id"
      [placeholder]="placeholder"
      [maxlength]="maxLength"
      (input)="onInput($event)"
      (blur)="onBlur()"
      [value]="value"
      [disabled]="disabled"
    ></ion-input>
    <info-popover *ngIf="info" [message]="info" [popoverId]="info"></info-popover>
  </ion-item>
  <way-error [error]="error"></way-error>
</div>
