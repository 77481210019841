import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function futureDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const inputDate = new Date(control.value);
    const today     = new Date();

    if (inputDate > today) {
      return { futureDate: true };
    }

    return null;
  };
}

export function pastDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const inputDate  = new Date(control.value);
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    if(inputDate < oneYearAgo) {
      return { pastDate: true };
    }

    return null;
  };
}
