<ion-menu contentId="main-content" type="overlay" [disabled]="disabledMenu">
  <ion-header>
    <ion-toolbar>
      <ion-title mode="md">
        <way-logo class="primary">{{ suffix }}</way-logo>
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <way-navigation [items]="menu"></way-navigation>
  </ion-content>
  <ion-footer>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col class="ion-text-start">{{ name }}</ion-col>
          <ion-col class="ion-text-center">{{ installationCode }}</ion-col>
          <ion-col class="ion-text-end">gds-{{ gdsName }}</ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</ion-menu>