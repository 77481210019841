<div class="input-container" [ngClass]="{ filled: variant === 'filled' }">
  <form [formGroup]="phoneForm">
    <div class="phone-input-wrapper">
      <div class="custom-dropdown flex" [ngClass]="size">
        <div
          class="selected-country flex items-center"
          (click)="toggleDropdown()"
          [class.open]="isDropdownOpen"
          tabindex="0"
          (keydown.enter)="toggleDropdown()"
          (keydown.space)="toggleDropdown()"
        >
          <way-flag [countryISO2]="selectedCountry"></way-flag>
          <tabler-icon icon="chevron-down" size="md"></tabler-icon>
        </div>

        <div class="dropdown-options" *ngIf="isDropdownOpen">
          <div
            *ngFor="let country of supportedCountries"
            class="dropdown-option"
            [class.selected]="country.PAY_CODE_ISO2 === selectedCountry"
            (click)="selectCountry(country.PAY_CODE_ISO2)"
            (keydown.enter)="selectCountry(country.PAY_CODE_ISO2)"
            tabindex="0"
          >
            <way-flag [countryISO2]="country.PAY_CODE_ISO2"></way-flag>
            <span class="country-code">{{ country.PAY_CODE_ISO2 }}</span>
          </div>
        </div>
      </div>

      <input
        [ngClass]="[size, isInvalid ? 'text-danger' : '']"
        type="tel"
        [placeholder]="placeholder"
        formControlName="phone"
        (input)="onPhoneInput($event)"
        class="phone-input"
      />
    </div>
  </form>
</div>
