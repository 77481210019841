<div class="mission-status font-semibold flex flex-col items-center gap-xs" *ngIf="mission">
  <ion-badge
    *ngIf="!mission.isCancelled && !mission.isAssigned"
    class="unassigned flex items-center justify-center"
  >
    {{ 'Affecter' | translate }}
  </ion-badge>

  <ion-badge *ngIf="mission.isPending" class="unconfirmed flex items-center justify-center">
    {{ 'Confirmer' | translate }}
  </ion-badge>

  <ion-badge *ngIf="mission.isBillable" class="billable flex items-center justify-center">
    {{ 'Facturer' | translate }}
  </ion-badge>
</div>
