import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';
import { CGdsMarqueRow, CGdsEnergieRow, CGdsTypeVehiculeRow } from '@way-lib-jaf/rowLoader';

export class CGdsModeleRow extends JafRow {
  @WayChamp({
    label: '%LAB_GDS_MODELE_ID%',
    class: 'Id',
  })
  public MOE_ID;

  @WayChamp({
    label: '%LAB_GDS_MODELE_LIBELLE%',
    class: 'Libelle',
  })
  public MOE_LIBELLE;

  @WayChamp({
    label  : '%LAB_GDS_MODELE_MAR_ID%',
    class  : 'Liaison',
    liaison: 'C_Gds_Marque',
    indexed: true,
  })
  public MOE_MAR_ID: CGdsMarqueRow;

  @WayChamp({
    label: '%LAB_GDS_MODELE_GAMME%',
    class: 'Texte',
  })
  public MOE_GAMME;

  @WayChamp({
    label: '%LAB_GDS_MODELE_NPR_ID%',
    class: 'Liaison',
  })
  public MOE_NPR_ID;

  @WayChamp({
    label: '%LAB_GDS_MODELE_CO2%',
    class: 'Quantite',
  })
  public MOE_CO2;

  @WayChamp({
    label: '%LAB_GDS_MODELE_PAX%',
    class: 'Quantite',
  })
  public MOE_PAX;

  @WayChamp({
    label: '%LAB_GDS_MODELE_NB_BAGAGE%',
    class: 'Quantite',
  })
  public MOE_NB_BAGAGE;

  @WayChamp({
    label  : '%LAB_GDS_MODELE_ENE_ID%',
    class  : 'Liaison',
    liaison: 'C_Gds_Energie',
    indexed: true,
  })
  public MOE_ENE_ID: CGdsEnergieRow;

  @WayChamp({
    label: '%LAB_GDS_MODELE_ANNEE%',
    class: 'Texte',
  })
  public MOE_ANNEE;

  // @WayChamp({
  //   label  : '%LAB_GDS_MODELE_TVH_ID%',
  //   class  : 'Liaison',
  //   // liaison: 'C_Gds_TypeVehicule',
  // })
  public MOE_TVH_ID: CGdsTypeVehiculeRow;

  get prestigeLibelle(): string {
    switch (this.MOE_NPR_ID) {
      case '1':
        return 'Eco';
      case '2':
        return 'Affaires';
      case '3':
        return 'Luxe';

      default:
        return 'Eco';
    }
  }
}
