import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Jaf } from '@way-lib/common/services/jaf/jaf';

/**
 * @property value : ISO-8601 date string (ex: 2021-06-04T14:23:00Z)
 */
interface DatetimeChangeEventDetail {
  value?: string | null;
}

interface DatetimeCustomEvent extends CustomEvent {
  detail: DatetimeChangeEventDetail;
  target: HTMLIonDatetimeElement;
}

@Component({
  selector   : 'way-partner-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls  : ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  @Input() id: string;

  @Input() control: FormControl;

  @Input() placeholder: string;

  @Input() maxDate?: string;

  @Input() minDate?: string;

  @Input() format = 'dd/MM';

  @Input() presentation: 'date' | 'year' | 'month-year' = 'date';

  @Input() showLabel = false;

  @Input() showError = false;

  public showCalendar: boolean = false;

  ngOnInit(): void {
    if (!this.maxDate) {
      const maxYear = new Date().getFullYear() + 2;
      this.maxDate  = maxYear.toString();
    }
    if (!this.minDate) {
      const minYear = new Date().getFullYear() - 30;
      this.minDate  = minYear.toString();
    }
  }

  toggleCalendarModal(state: boolean): void {
    this.showCalendar = state;
  }

  onSelectDate(e: DatetimeCustomEvent): void {
    const rawDate = e.detail.value;

    if (!rawDate) return;

    const date = rawDate.slice(0, 10);

    this.control.setValue(Jaf.date2mysql(date));
  }

  onBlur(): void {
    if (!this.control.value) {
      this.control.setValue(Jaf.date2mysql(new Date()));
    }
  }

  getFormattedDate(date: string): string {
    return formatDate(date, this.format, Jaf.LAN_CODE);
  }
}
