<ion-icon [name]="iconName" [id]="popoverId" slot="end" class="info-icon"></ion-icon>
<ion-popover mode="ios" [trigger]="popoverId" triggerAction="hover" side="top" alignment="center">
  <ng-template>
    <ion-content class="ion-padding">
      <ion-text>
        {{ message | translate }}
      </ion-text>
    </ion-content>
  </ng-template>
</ion-popover>
