import { Component, Input } from '@angular/core';

@Component({
  selector   : 'ai-icon',
  templateUrl: './ai-icon.component.html',
  styleUrls  : ['./ai-icon.component.scss'],
})
export class AiIconComponent {
  @Input() valid: boolean;
}
