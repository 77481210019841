import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage-angular';
import { ConceptManager } from '@way-lib-jaf/concept-manager';
import { Jaf } from './jaf';
import { JafRow } from './row';
import * as rowLoader from './rowLoader';

export class JafConcept {
  rowAttente = [];

  public _flagUniqueRowset = false;

  private _all: Array<JafRow>;

  indexRowset;

  listIndex = [];

  public lastId = 0;

  listRowOberver: Map<string, any> = new Map<string, any>();

  public builds = [];

  public primary: string;

  protected name: string;

  protected class: string;

  protected rowClass: string;

  protected trigramme: string;

  protected champs = {};

  protected _flagSaveLocal = false;

  public hasService = false;

  protected _isInit = false;

  /* les listes */
  public actionWait = [];

  public orderAction = []; // ordre de lancement des actions

  private _bindfilter = new Map<string, Array<string>>();

  constructor(
    public cm: ConceptManager,
    // public logger: NGXLogger,
    public http: HttpClient,
    public storage: Storage,
  ) {}

  init() {
    if (!this._isInit) {
      // creation des indexes
      const row        = new rowLoader[this.rowClass](this, {});
      this.indexRowset = new Map<string, any>();
      const champs     = row.getChamps();
      Object.keys(champs).forEach((nc) => {
        if (champs[nc].indexed) {
          this.addIndex(nc);
        }
      });
      this.rebuildIndexs();
      if (this.hasService) this.cm.addService(this);
      this._isInit = true;
    }
  }

  get all() {
    if (this._all === undefined) {
      this.build_all();
    }
    return this._all;
  }

  private onBuildAllBind = [];

  onBuildAll(callback) {
    this.onBuildAllBind.push(callback);
    if (this._all === undefined) {
      this.build_all();
    } else {
      this.executeOnBuildAll()
    }
  }

  build_all() {
    if (this._all === undefined) {
      this._all = new Array<JafRow>();
    } else {
      this._all.splice(0, this._all.length);
    }
    if (this._flagUniqueRowset) {
      this.getIndex(this.primary).forEach((value) => {
        this._all.push(value);
      });
    } else {
      this.cm.getDatabases().forEach((database) => {
        this.getIndex(this.primary, database).forEach((value) => {
          this._all.push(value);
        });
      });
    }
    this.executeOnBuildAll()
  }

  executeOnBuildAll() {
    this.onBuildAllBind.forEach((callback) => {
      callback(this._all);
    });
    this.onBuildAllBind = [] // flush callbacks after execution
  }

  arrayMerge(a1, a2) {
    a1.splice(0, a1.length);
    a2.forEach((v) => {
      a1.push(v);
    });
  }

  addBuild(liste) {
    if (liste) {
      liste.forEach((method: string) => {
        if (this.actionWait.indexOf(method) === -1) {
          this.actionWait.push(method);
        }
      });
    }
  }

  launchBuildByNameField(champ: string) {
    // on demande le build_all s'il a déja été appelé quand un row est ajouté ou supprimé
    if (champ === this.primary && this._all !== undefined) {
      this.cm.addService(this);
      this.addBuild(['build_all']);
    }
    this.addBuild(this._bindfilter.get(champ));
  }

  // relie les changements d'un champ d'un row au lancement d'un build
  bindfilter(lc: Array<string>, methods: Array<string>) {
    lc.forEach((champ) => {
      const liste = this._bindfilter.get(champ);
      if (liste) {
        methods.forEach((method) => {
          if (liste.indexOf(method) === -1) {
            liste.push(method);
          }
        });
      } else {
        this._bindfilter.set(champ, methods);
      }
    });
  }

  makeActions() {
    this.actionWait.forEach((action) => {
      this[action]();
    });
  }

  getRowClass() {
    return this.rowClass;
  }

  getClass() {
    return this.class;
  }

  getTrigramme() {
    return this.trigramme;
  }

  addIndex(champ) {
    if (!this.listIndex.includes(champ)) {
      this.listIndex.push(champ);
    }
    return this;
  }

  getCleIndex(database, champ) {
    if (this._flagUniqueRowset) return champ;
    return `${database}-${champ}`;
  }

  getIndexs(cle) {
    if (!this.indexRowset.has(cle)) {
      this.indexRowset.set(cle, new Map<string, Array<JafRow>>());
    } else if ( this.indexRowset.get(cle) === undefined ) {
        this.indexRowset.set(cle, new Map<string, Array<JafRow>>());
    }
    return this.indexRowset.get(cle);
  }


  rebuildIndexs() {
    this.cm.getDatabases().forEach((database) => {
      this.listIndex.forEach((champ) => {
        this.getIndexs(this.getCleIndex(database, champ));
      });
      this.getIndexs(this.getCleIndex(database, this.primary));
    });
  }

  getIndex(champ, database?) {
    const cle = this.getCleIndex(database, champ);
    if (this.indexRowset.has(cle)) {
      return this.indexRowset.get(cle);
    }
    return false;
  }

  getIndexSort(c, database, id, tris?) {
    const value  = typeof id === 'object' ? id.join('c') : id;
    const indexs = this.getIndex(c, database);
    // console.log(c, database, this.indexRowset);
    if (!indexs) {
      console.error('Ajouter indexed:true sur @WayChamp de : ', c, this.class);
    }
    if (indexs.has(value)) {
      const rowset = indexs.get(value);
      if (rowset) Jaf.sortSpecial(rowset, tris);
      return rowset;
    }
    const rowset = [];
    indexs.set(value, rowset);
    return rowset;
  }

  /**
   *
   * @param c Concept's "liaison" key (i.e : "COM_CLI_ID")
   * @param database String id of the database (i.e : "wp1534")
   * @param id Value of the primary Id of the targetted "liaison" (i.e : CLI_ID = 153400000000000)
   * @returns Array of matching rows.
   * @notice This method searches for row instances of this concept whose searched "liaison" (@param c)'s primary
   * key value matches the given id (@param id) in the given database (@param database)
   *
   */
  getRowsetByIndex(c, database, id, tris?) {
    const value  = typeof id === 'object' ? id.join('c') : id;
    const indexs = this.getIndex(c, database);
    if (!indexs || !indexs.has(value)) {
      // console.log(`getRowsetByIndex : erreur sur ${c} ${database} ${value}`);
    }

    const rowset = [];
    const index  = indexs.get(value);
    if (index) {
      index.forEach((item) => {
        rowset.push(item);
      });
    }
    Jaf.sortSpecial(rowset, tris);
    return rowset;
  }

  removeData() {
    if (this.indexRowset) {
      this.indexRowset.clear();
      this.rebuildIndexs();
    }
    this.launchBuildByNameField(this.primary);
    this.askToSaveDataLocale();
  }

  toJSON() {
    const json = {};
    this.indexRowset.forEach((rowset, cle) => {
      const tab = [];
      if (rowset.size > 0) {
        rowset.forEach((row, c) => {
          if (Array.isArray(row)) {
            const t = [];
            row.forEach((r) => {
              t.push(r.toJSON());
            });
            json[`${cle}-${c}`] = t;
          } else {
            tab.push(row.toJSON());
          }
        });
        json[cle] = tab;
      } else {
        json[cle] = 'pas de rowset';
      }
    });
    return json;
  }

  saveDataLocale() {
    if (this._flagSaveLocal) {
      if (this._flagUniqueRowset) {
        const rowset = [];
        if (this.getIndex(this.primary)) {
          this.getIndex(this.primary).forEach((row) => {
            rowset.push(row.toJSON());
          });
          this.storage.set(`${this.name}.rowset`, rowset);
        }
      } else {
        this.cm.getDatabases().forEach((database) => {
          const rowset = [];
          this.getIndex(this.primary, database).forEach((row) => {
            if (row.database === database) {
              rowset.push(row.toJSON());
            }
          });
          this.storage.set(`${database}.${this.name}.rowset`, rowset);
          // console.log(`je sauve ${database}.${this.name}.rowset`,rowset);
        });
      }
      this._flagSaveLocal = false;
    }
  }

  askToSaveDataLocale() {
    if (!this._flagSaveLocal) {
      this._flagSaveLocal = true;
      /**
       * Pas de setTimeout pour nf_gds_installation ne peut etre loadDataLocale car elle est en attente de saveDataLocale
       */
      if (this.name === 'nf_gds_installation') {
        this.saveDataLocale();
      } else {
        setTimeout(() => {
          this.saveDataLocale();
        }, 500);
      }
    }
  }

  async loadDataLocale() {
    let loadingError = false
    if (undefined !== rowLoader[this.rowClass]) {
      if (!this._flagSaveLocal) {
        if (this._flagUniqueRowset) {
          const storageRowset = await this.storage.get(`${this.name}.rowset`);
          if (storageRowset) {
            storageRowset.forEach((storageRow) => {
              this.setRowAux(storageRow);
            });
          }
        } else {
          const listeChargement = [];
          this.cm.getDatabases().forEach((database) => {
            listeChargement.push(
              this.storage.get(`${database}.${this.name}.rowset`).then((storageRowset) => {
                if (storageRowset){
                  storageRowset.forEach((storageRow) => {
                    this.setRowAux(storageRow, database);
                  });
                  return true
                }
                  return false

              }),
            );
          });
          const loaded = await Promise.all(listeChargement);
          loadingError = !loaded.every(isLoaded => isLoaded === true)
          if(!loadingError){ // ensure all storage keys exist before calling build_all
            this.build_all();
          }
        }
        this._flagSaveLocal = false;
      } else {
        loadingError = true
        console.error(
          `${this.name} ne peut etre loadDataLocale car elle est en attente de saveDataLocale`,
        );
      }
    } else {
      loadingError = true
    }
    return loadingError
  }

  getCle(id, database?) {
    if (this._flagUniqueRowset) {
      return id;
    }
    return `${database}-${id}`;
  }

  doPrimary(row) {
    if (this._flagUniqueRowset) {
      this.getIndexs(this.primary).set(row.getIdentity(), row);
    } else {
      this.getIndexs(`${row.database}-${this.primary}`).set(row.getIdentity(), row);
    }
  }

  removePrimary(row) {
    if (this._flagUniqueRowset) {
      this.getIndexs(this.primary).delete(row.getIdentity());
    } else {
      this.getIndexs(`${row.database}-${this.primary}`).delete(row.getIdentity());
    }
  }

  doIndex(row) {
    this.listIndex.forEach((name) => {
      let cle = row[name];
      if (cle !== null && typeof row[name] === 'object') {
        cle = row[name].getIdentity();
      }
      if (cle !== '' && cle !== null && cle !== undefined) {
        const indexs = this.getIndex(name, row.database);
        if (indexs) {
          if (!indexs.has(cle)) {
            indexs.set(cle, [row]);
          } else {
            const tab = indexs.get(cle);
            let flag  = false;
            tab.forEach((item) => {
              if (item === row) flag = true;
            });
            if (!flag) {
              tab.push(row);
            }
          }
        }
      }
    });
  }

  removeIndex(row) {
    this.listIndex.forEach((name) => {
      let cle = row[name];
      if (cle !== null && typeof row[name] === 'object') {
        cle = row[name].getIdentity();
      }
      if (cle !== '' && cle !== null && cle !== undefined) {
        const indexs = this.getIndex(name, row.database);
        if (indexs && indexs.has(cle)) {
          const tab = indexs.get(cle);
          const pos = tab.indexOf(row);
          if (pos !== -1) {
            tab.splice(pos, 1);
          }
        }
      }
    });
  }

  build_listRowOberver(row) {
    setTimeout(() => {
      const cle = this.getCleIndex(row.database, row[this.primary]);
      if (this.listRowOberver.has(cle)) {
        this.listRowOberver.get(cle).forEach((successCallBack) => {
          successCallBack(row);
        });
      }
    });
  }

  setRow(row: JafRow) {
    this.doPrimary(row);
    this.doIndex(row);
    if (this._all !== undefined) {
      this.addBuild(['build_all']);
    }
    this.build_listRowOberver(row);
    return this;
  }

  getRow(id, database?) {
    if (this._flagUniqueRowset) {
      return this.indexRowset.has(this.primary) ? this.indexRowset.get(this.primary).get(id) : null;
    }
    if (this.name==="nf_gds_installation") {
      // console.log('getRow', {id, database, index: this.indexRowset});
    }
    return this.indexRowset && this.indexRowset.has(`${database}-${this.primary}`)
      ? this.indexRowset.get(`${database}-${this.primary}`).get(id)
      : null;
  }

  deleteRow(row) {
    this.removeIndex(row);
    this.removePrimary(row);
    this.launchBuildByNameField(this.primary);
    const cle = this.getCleIndex(row.database, row[this.primary]);
    if (this.listRowOberver.has(cle)) {
      this.listRowOberver.get(cle).forEach((successCallBack) => {
        successCallBack(null);
      });
    }
    this.askToSaveDataLocale();
  }

  // flagInit permet de ne pas synchroniser les données de row
  setRowAux(row, database?, flagInit = true) {
    const rowLocal = this.getRow(row[this.primary], database);
    if (rowLocal) {
      Object.entries(row).forEach(([nc, valeur]) => {
        rowLocal.setterStorage(nc, valeur);
      });
      this.build_listRowOberver(rowLocal);
      return true;
    }
    this.setRow(new rowLoader[this.rowClass](this, row, database));
    if (flagInit) {
      this.getRow(row[this.primary], database).videDataModified();
    }
    return false;
  }

  setRowsetAvecNomChamp(rowset, listeChamp, database, flagRemoveData = false) {
    if (undefined !== rowLoader[this.rowClass]) {
      const tabIdPresent = [];
      const indexs       = this.getIndex(this.primary, database);
      if (!indexs) {
        console.error(
          `Erreur sur setRowsetAvecNomChamp sur ${this.name} pour ${this.primary} sur ${database}`,
          this.indexRowset,
        );
        return this;
      }
      indexs.forEach((row) => {
        if (row.database === database) {
          tabIdPresent.push(row.getIdentity());
        }
      });
      rowset.forEach((data) => {
        const row = {};
        let cpt   = 0;
        listeChamp.forEach((nc) => {
          row[nc] = data[cpt];
          cpt    += 1;
        });
        if (this.setRowAux(row, database)) {
          tabIdPresent.splice(tabIdPresent.indexOf(row[this.primary]), 1);
        }
      });
      // on retire les elements non présent
      if (flagRemoveData) {
        tabIdPresent.forEach((id) => {
          const row = this.getRow(id, database);
          if (row) {
            row.localDelete();
          }
        });
      }
      this.askToSaveDataLocale();
    }
    return this;
  }

  getRowAsynAux(monId, database) {
    const row = this.getRow(monId, database);
    if (!row) {
      setTimeout(() => {
        this.getRowAsynAux(monId, database);
      }, 250);
    } else {
      this.build_listRowOberver(row);
    }
  }

  async getRowAsyn(monId, database, successCallBackInitiale) {
    const cle = this.getCleIndex(database, monId);
    if (!this.listRowOberver.has(cle)) {
      this.listRowOberver.set(cle, []);
    }
    this.listRowOberver.get(cle).push(successCallBackInitiale);
    this.getRowAsynAux(monId, database);
  }

  getArrayFilterSortByDatabase(database, filter = null, sortFunction = null) {
    const res = [];
    this.getIndex(this.primary, database).forEach((row: JafRow) => {
      if (!filter || filter(row)) {
        res.push(row);
      }
    });
    if (sortFunction) {
      res.sort(sortFunction);
    }
    return res;
  }

}
