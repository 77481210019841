import { Pipe, PipeTransform } from '@angular/core';
import { Jaf } from '@way-lib/common/services/jaf/jaf';
import { CGenClientRow } from '@way-lib/models/Gen/CGenClientRow';

@Pipe({ name: 'ClientFilter' })
export class ClientFilterPipe implements PipeTransform {
  transform(clients: CGenClientRow[], searchKeyword: string): CGenClientRow[] {
    return clients?.filter((client: CGenClientRow) => {
      const clientDetails = [
        client.CLI_SOCIETE,
        `${(client?.cot?.COT_PRENOM ?? '') + (client?.cot?.COT_NOM ?? '')}`,
        `${(client.cot?.COT_NOM ?? '') + (client?.cot?.COT_PRENOM ?? '')}`,
        client?.cot?.COT_TELEPHONE,
        client?.cot?.COT_MOBILE,
        client?.cot?.COT_EMAIL,
      ];

      const formattedKeyword = Jaf.toUpperCaseSansAccent(searchKeyword).replaceAll(' ', '');

      if (!formattedKeyword) {
        return true;
      }

      const isMatch = clientDetails.some((detail) =>
        Jaf.toUpperCaseSansAccent(detail).replaceAll(' ', '').includes(formattedKeyword),
      );

      return isMatch;
    });
  }
}