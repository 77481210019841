import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Jaf } from '@way-lib/common/services/jaf/jaf'

@Component({
  templateUrl: './generic-picker-modal.component.html',
  styleUrls  : ['./generic-picker-modal.component.scss'],
})
export class GenericPickerModalComponent {
  @Input() items: any[] = [];

  @Input() selected: any;

  @Input() displayField: string;

  @Input() valueField: string;

  @Input() translate = true

  public Jaf = Jaf

  constructor(public modalController: ModalController) {}

  public selectItem(item: any): void {
    this.modalController.dismiss(item[this.valueField]);
  }
}
