import { Pipe, PipeTransform } from '@angular/core';
import { CGdsModeleRow } from '@way-lib-jaf/rowLoader';

@Pipe({ name: 'VehicleModelFilter' })
export class VehicleModelFilterPipe implements PipeTransform {
  transform(items: CGdsModeleRow[], keyword: string): CGdsModeleRow[] {
    return items?.filter((item: CGdsModeleRow) => {
      if (!keyword) {
        return true;
      }
      const inVehicleBrand =
        item.MOE_MAR_ID?.MAR_LIBELLE &&
        item.MOE_MAR_ID?.MAR_LIBELLE.toLowerCase().includes(keyword.toLowerCase());
      const inVehicleModel = item.MOE_LIBELLE.toLowerCase().includes(keyword.toLowerCase());
      return inVehicleBrand || inVehicleModel;
    });
  }
}
