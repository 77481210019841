<div class="input-container" (click)="toggleLocationModal(true)">
  <ion-input
    class="location-input"
    labelPlacement="stacked"
    label="location"
    [placeholder]="
      id === 'dropOffLocation'
        ? ('Lieu de dépose' | translate)
        : ('Lieu de prise en charge' | translate)
    "
    [value]="control?.value ? control?.value.LIE_FORMATED : ''"
  >
    <tabler-icon icon="map-pin" size="sm"></tabler-icon>
  </ion-input>
</div>

<ion-modal
  [id]="id"
  [initialBreakpoint]="0.75"
  [breakpoints]="[0, 0.75, 1]"
  mode="ios"
  [isOpen]="showLocationModal"
  (willDismiss)="toggleLocationModal(false)"
  cssClass="location-modal"
>
  <ng-template>
    <div class="location-modal-content">
      <h3 class="head-title">
        {{
          id === 'dropOffLocation'
            ? ('Lieu de dépose' | translate)
            : ('Lieu de prise en charge' | translate)
        }}
      </h3>
      <way-partner-destination-list
        (itemClick)="onSelectLocation($event)"
      ></way-partner-destination-list>
    </div>
  </ng-template>
</ion-modal>
