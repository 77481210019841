import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/browser";

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as CONSTANTS from '@way-lib/common/constant';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const achid = localStorage.getItem(CONSTANTS.IONIC_STORAGE_KEY_ACHID);
if(achid){
  Sentry.setTag(CONSTANTS.STORAGE_KEY_ACHID, achid);
}

Sentry.init({
  dsn                     : "https://3848c5cd9726327379f66bb43aa970fe@o4506303632506880.ingest.sentry.io/4506303649808385",
  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release                 : `${environment.npmName}@${environment.npmPackageVersion}`,
  integrations            : [new Sentry.BrowserTracing(), new Sentry.Replay()],
  environment             : environment.name,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate        : 1.0,
  // Capture Replay for 1% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
});

// eslint-disable-next-line no-console
platformBrowserDynamic().bootstrapModule(AppModule).catch(console.error);

defineCustomElements(window);
