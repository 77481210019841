import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConceptManager } from '@way-lib/common/services/jaf/concept-manager';
import { Jaf } from '@way-lib/common/services/jaf/jaf';
import { CGenEtapePresenceRow } from '@way-lib/models/Gen/CGenEtapePresenceRow';
import { CGenMissionRow } from '@way-lib/models/Gen/CGenMissionRow';

export enum MissionTicketView {
  NEXT_MISSION = 'NEXT_MISSION',
  MISSION_ACTION = 'MISSION_ACTION',
}

@Component({
  selector   : 'mission-ticket',
  templateUrl: './mission-ticket.component.html',
  styleUrls  : ['./mission-ticket.component.scss'],
})
export class MissionTicketComponent {
  @Input() mission: CGenMissionRow;

  @Input() view: MissionTicketView = MissionTicketView.NEXT_MISSION;

  public Jaf = Jaf;

  constructor(private router: Router, private cm: ConceptManager) {}

  public navigateToDetails(): void {
    if (!this.cm.getDatabase() || !this.mission) return;

    if (this.view === MissionTicketView.NEXT_MISSION) {
      this.router.navigate(['/home']);
      return;
    }

    this.router.navigate(['/missions', 'detail', this.cm.getDatabase(), this.mission.MIS_ID]);
  }

  public get missionStartStep(): CGenEtapePresenceRow | null {
    const startStep = this.mission.eprs[0];
    return startStep || null;
  }

  public get isActionView(): boolean {
    return this.view === MissionTicketView.MISSION_ACTION;
  }

  public get timeBeforeMission(): { value: number; unit: string } | null {
    const now         = new Date();
    const missionDate = new Date(this.mission.MIS_DATE_DEBUT);

    if (this.mission.MIS_HEURE_DEBUT) {
      const [hours, minutes] = this.mission.MIS_HEURE_DEBUT.split(':');
      missionDate.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
    }

    const diffMs = missionDate.getTime() - now.getTime();

    if (diffMs < 0) {
      return null;
    }

    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));

    if (diffHours < 1) {
      const diffMinutes = Math.floor(diffMs / (1000 * 60));
      return { value: diffMinutes, unit: 'minute' };
    }

    if (this.daysBeforeMission === 0) {
      return { value: diffHours, unit: 'heure' };
    }

    return null;
  }

  public get daysBeforeMission(): number {
    const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
    const today         = new Date();
    today.setHours(0, 0, 0, 0);

    const missionDate = new Date(this.mission.MIS_DATE_DEBUT);
    missionDate.setHours(0, 0, 0, 0);

    const dayInterval = missionDate.getTime() - today.getTime();
    return Math.ceil(dayInterval / ONE_DAY_IN_MS);
  }

  public get missionDurationInHours(): string {
    if (!this.mission.MIS_HEURE_FIN || !this.mission.MIS_HEURE_DEBUT) return '';

    const duration = Jaf.subtractHours(
      this.mission.MIS_HEURE_FIN,
      this.mission.MIS_HEURE_DEBUT,
    ).slice(0, 5);

    if (!duration || duration === '00:00') return '';

    const hours   = parseInt(duration.slice(0, 2), 10);
    const minutes = parseInt(duration.slice(3, 5), 10);

    return hours ? `${hours}h` : `${minutes}m`;
  }
}
