<div *ngIf="control && id" class="input-container" (click)="toggleClockModal(true)">
  <tabler-icon
    [icon]="durationMode() ? 'alarm' : 'clock-hour-7'"
    size="sm"
    color="d2"
  ></tabler-icon>
  <ion-datetime-button [datetime]="id"></ion-datetime-button>
  <ion-modal
    [keepContentsMounted]="true"
    [isOpen]="showClock"
    (didDismiss)="toggleClockModal(false)"
  >
    <ng-template>
      <ion-datetime
        [id]="id"
        [formControl]="control"
        locale="fr-FR"
        presentation="time"
        (ionBlur)="onBlur()"
        [showDefaultButtons]="true"
        [doneText]="'Confirmer' | translate"
        [cancelText]="'Annuler' | translate"
      ></ion-datetime>
    </ng-template>
  </ion-modal>
  <span class="input-value">{{
    control?.value
      ? Jaf.getTimeWithoutSeconds(control?.value)
      : durationMode()
      ? ('Durée' | translate)
      : ('Heure' | translate)
  }}</span>
  <tabler-icon icon="chevron-down" size="md"></tabler-icon>
</div>
