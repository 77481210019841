import npmPackage from '../../package.json';

export const environment = {
  production: false, // if true enable serviceWorker that create infinite reloading on local developpement
  name      : 'stage',
  apiRootUrl: {
    geolocalisation   : 'https://stage-gdspartner.waynium.net',
    evenementChauffeur: 'https://stage-gdspartner.waynium.net',
    divers            : 'https://stage-gdspartner.waynium.net',
    flight            : 'https://stage-flight.waynium.net',
    timePrediction    : 'https://stage-predict.waynium.net'
  },
  npmPackageVersion: npmPackage.version,
  npmName          : npmPackage.name,
  firebaseConfig   : {
    apiKey           : "AIzaSyC6dppnVe0_sI6njZ9_bv0ktJ-71HHuIK0",
    authDomain       : "way-sandbox.firebaseapp.com",
    projectId        : "way-sandbox",
    storageBucket    : "way-sandbox.appspot.com",
    messagingSenderId: "126693302504",
    appId            : "1:126693302504:web:5748a7b0cd9efd5e7ae95d",
    measurementId    : "G-KJ895396M0"
  }
};
