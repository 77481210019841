import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { Gds } from '@way-lib-jaf/gds'
import { ConceptManager } from '@way-lib-jaf/concept-manager'

@Injectable({
  providedIn: 'root',
})
export class PlaceAutocompleteService {
  foundLocations: Subject<any> = new Subject<any>()

  constructor(private gds: Gds, private cm: ConceptManager) {}

  getLocation(address: string) {
    return this.gds.post(
      'divers',
      '/wayp/get-lieu-by-address',
      { address }
    ).subscribe(
      (response) => {
        this.foundLocations.next(response[this.cm.getDatabase()])
      });
  }
}
