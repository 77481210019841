import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CodeWayPartnerService {
  private static readonly OFFSET = 100000;

  private static readonly PERMUTATION = [
    3, 18, 17, 0, 14, 16, 11, 7, 8, 9, 10, 6, 12, 13, 4, 15, 5, 2, 1,
  ];

  private static convertToBinary(value: number): number[] {
    const binaryRepresentation: number[] = [];
    let remainingValue                   = value;

    while (remainingValue > 0) {
      binaryRepresentation.push(remainingValue % 2);
      remainingValue = Math.floor(remainingValue / 2);
    }

    return binaryRepresentation;
  }

  private static applyPermutation(value: number): number {
    const binaryDigits = this.convertToBinary(value);
    let permutedValue  = 0;

    binaryDigits.forEach((digit, index) => {
      if (digit === 1) {
        permutedValue += 2**this.PERMUTATION[index];
      }
    });

    return permutedValue;
  }

  static getCodeByInsId(installationId: number): string {
    const permutedValue = this.applyPermutation(installationId);
    const codeValue     = this.OFFSET + permutedValue;

    const formattedCode = codeValue.toString().padStart(6, '0');

    return `${formattedCode.substring(0, 3)}-${formattedCode.substring(3, 6)}`;
  }
}
