import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConceptManager } from '@way-lib-jaf/concept-manager';
import { CGdsEnergie, CGdsModele } from '@way-lib-jaf/conceptLoader';
import {
  CGdsEnergieRow,
  CGdsMarqueRow,
  CGdsModeleRow,
  CGdsNiveauPrestigeRow,
  CGdsTypeVehiculeRow,
  CGenVoitureRow,
} from '@way-lib-jaf/rowLoader';
import { format, formatISO } from 'date-fns';
import { ModalController } from '@ionic/angular';
import { Jaf } from '@way-lib/common/services/jaf/jaf';
import { futureDateValidator } from '@way-lib/common/form-validators/date.validator';
import { GenericPickerModalComponent } from '@way-partner/components/generic-picker-modal/generic-picker-modal.component';
import { Gds } from '@way-lib/common/services/jaf/gds';
import { ErrorService } from '@way-partner/services/error.service';
import { SignUpTunnelForms } from '@way-partner/pages/auth/signup/signup-form-types';

const MAX_PASSENGERS = 25;
const MAX_LUGGAGES   = 25;

type VehicleFormValues = {
  licensePlate: string;
  brandId: string;
  model: CGdsModeleRow;
  firstRegistrationDate: string;
};

export enum VehicleFormMode {
  SIGNUP = 'SIGNUP',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

@Component({
  selector   : 'way-partner-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls  : ['./vehicle-form.component.scss'],
})
export class VehicleFormComponent {
  @Input() vehicle: CGenVoitureRow;

  @Input() mode: VehicleFormMode;

  @Output()
  formChange = new EventEmitter<{ form: FormGroup; type: SignUpTunnelForms }>();

  @Output() vehicleChange = new EventEmitter<{ vehicle: CGenVoitureRow }>();

  public vehicleForm: FormGroup<{
    [K in keyof VehicleFormValues]: FormControl<VehicleFormValues[K]>;
  }>;

  public customVehicleForm: FormGroup;

  public vehicles: CGenVoitureRow[] = [];

  public models: CGdsModeleRow[] = [];

  public brands: CGdsMarqueRow[] = [];

  public energies: CGdsEnergieRow[] = [];

  public vehicleTypes: CGdsTypeVehiculeRow[] = [];

  public prestiges: CGdsNiveauPrestigeRow[] = [];

  private formSubscriptions: Subscription[] = [];

  public conceptEnergy: CGdsEnergie;

  public conceptModel: CGdsModele;

  public maxDate: string;

  public minDate: string;

  public submitted = false;

  public submittedCustomVehicle = false;

  public showModelSelectionModal = false;

  public showCustomVehicleForm = false;

  public keyword: string;

  private database: string;

  public Jaf = Jaf;

  public VehicleFormMode = VehicleFormMode;

  constructor(
    private gds: Gds,
    private formBuilder: FormBuilder,
    private cm: ConceptManager,
    private cdf: ChangeDetectorRef,
    private modalController: ModalController,
    private errorService: ErrorService,
  ) {}

  public initComponent(formValues?: VehicleFormValues): void {
    this.cm.setOnLoad(() => {
      this.database      = this.cm.getDatabase();
      this.conceptEnergy = this.cm.getConcept('C_Gds_Energie');
      this.conceptModel  = this.cm.getConcept('C_Gds_Modele');
      this.brands        = this.cm.getConcept('C_Gds_Marque').all;
      this.vehicleTypes  = this.cm.getConcept('C_Gds_TypeVehicule').vehicleTypes;
      this.prestiges     = this.cm.getConcept('C_Gds_NiveauPrestige').all;
      this.energies      = <CGdsEnergieRow[]>this.conceptEnergy.all;
      this.maxDate       = formatISO(new Date(), { representation: 'date' });
      this.minDate       = formatISO(new Date('1970'), { representation: 'date' });

      this.initForm(formValues);
      this.registerFormEvents();

      if (!this.isSignUpView()) return;

      this.formChange.emit({ form: this.vehicleForm, type: SignUpTunnelForms.VEHICLE });
    });
  }

  private initForm(formValues?: VehicleFormValues): void {
    const registrationDate = this.vehicle?.VOI_DATE_ENTREE
      ? this.vehicle?.VOI_DATE_ENTREE
      : new Date();

    this.vehicleForm = this.formBuilder.group({
      licensePlate: [
        formValues?.licensePlate ?? this.vehicle?.VOI_LIBELLE ?? '',
        Validators.required,
      ],
      brandId: [
        formValues?.brandId ?? this.vehicle?.VOI_MOE_ID?.MOE_MAR_ID?.MAR_ID ?? '',
        Validators.required,
      ],
      model                : [formValues?.model ?? this.vehicle?.VOI_MOE_ID ?? null, Validators.required],
      firstRegistrationDate: [
        formValues?.firstRegistrationDate ?? format(registrationDate, 'yyyy-MM-dd') ?? '',
        [Validators.required, futureDateValidator()],
      ],
    });

    if (formValues?.brandId) {
      this.loadModels(formValues.brandId);
    }
  }

  private registerFormEvents(): void {
    this.formSubscriptions.push(
      this.vehicleForm.controls.licensePlate.valueChanges.subscribe((newLicense) =>
        this.formatLicensePlate(newLicense),
      ),
      this.vehicleForm.controls.brandId.valueChanges.subscribe(() =>
        this.vehicleForm.controls.model.reset(),
      ),
    );

    if (!this.isSignUpView()) return;

    this.formSubscriptions.push(
      this.vehicleForm.valueChanges.subscribe(() => {
        this.formChange.emit({ form: this.vehicleForm, type: SignUpTunnelForms.VEHICLE });
      }),
    );
  }

  public showError(formControl: FormControl, isFormSubmitted: boolean): boolean {
    return formControl?.errors?.required && isFormSubmitted;
  }

  private formatLicensePlate(newLicense: string): void {
    const previousLicensePlate = this.vehicleForm.value.licensePlate;

    if (
      previousLicensePlate.length !== newLicense.length - 1 ||
      (newLicense.length !== 2 && newLicense.length !== 6)
    ) {
      return;
    }

    this.vehicleForm.controls.licensePlate.setValue(`${newLicense}-`);
  }

  public toggleModelSelection(state: boolean): void {
    this.showModelSelectionModal = state;

    if (!state) {
      this.keyword                = '';
      this.showCustomVehicleForm  = false;
      this.submittedCustomVehicle = false;
    }
  }

  public onSelectModel(model: CGdsModeleRow): void {
    this.vehicleForm.controls.model.setValue(model);
    this.toggleModelSelection(false);
  }

  public onFilterModels(event: any): void {
    this.keyword = event.detail.value;
  }

  private loadModels(brandId: string) {
    this.models = this.conceptModel.getRowsetByIndex(
      'MOE_MAR_ID',
      this.database,
      brandId,
      'MOE_LIBELLE',
    );
  }

  public removeSelectedModel(): void {
    this.vehicleForm.controls.model.setValue(null);
    this.toggleModelSelection(true);
  }

  public toggleCustomVehicleForm(state: boolean): void {
    this.showCustomVehicleForm = state;

    if (state) {
      this.initCustomVehicleForm();
    }
  }

  private initCustomVehicleForm(): void {
    this.customVehicleForm = this.formBuilder.group({
      model       : [this.keyword, Validators.required],
      vehicleDate : ['', Validators.required],
      energy      : ['', Validators.required],
      type        : ['', Validators.required],
      maxPassenger: [1, Validators.required],
      maxLuggages : [1, Validators.required],
      prestige    : ['', Validators.required],
    });
  }

  public submitCustomVehicle(): void {
    this.submittedCustomVehicle = true;

    if (this.customVehicleForm.invalid) return;

    this.toggleModelSelection(false);

    const { model, vehicleDate, energy, type, maxPassenger, maxLuggages, prestige } =
      this.customVehicleForm.value;

    const payload = {
      MOE_LIBELLE  : model,
      MOE_ENE_ID   : energy,
      MOE_MAR_ID   : this.vehicleForm.value.brandId,
      MOE_NPR_ID   : prestige,
      MOE_TVH_ID   : type,
      MOE_PAX      : maxPassenger,
      MOE_NB_BAGAGE: maxLuggages,
      MOE_ANNEE    : Number(vehicleDate.split('-')[0]),
    };

    this.gds.post('divers', '/gdsv3/create-modele', payload).subscribe({
      next: (rawModel) => {
        const newModel = new CGdsModeleRow(
          this.conceptModel,
          { ...rawModel, MOE_MAR_ID: this.getSelectedBrand() || this.vehicleForm.value.brandId },
          this.cm.getDatabase(),
        );
        newModel.setDatas(newModel, false);
        this.conceptModel.setRow(newModel);

        const newModelEnergy = this.energies.find(
          (e) => e.ENE_ID === newModel.MOE_ENE_ID.toString(),
        );

        if (newModelEnergy) {
          newModel.MOE_ENE_ID = newModelEnergy;
        }

        this.vehicleForm.controls.model.setValue(newModel);
      },
      error: (error: any) => this.errorService.handleError(error),
    });
  }

  public stepSubmit(): boolean {
    this.submitted = true;

    if (this.vehicleForm.invalid) return false;

    const { licensePlate, model, firstRegistrationDate } = this.vehicleForm.value;

    const vehiclePayload = {
      VOI_MOE_ID     : model.MOE_ID,
      VOI_LIBELLE    : licensePlate.toUpperCase(),
      VOI_DATE_ENTREE: new Date(firstRegistrationDate).toISOString(),
    };

    if (!this.vehicle) {
      this.vehicle = new CGenVoitureRow(
        this.cm.getConcept('C_Gen_Voiture'),
        {},
        this.cm.getDatabase(),
      );
    }

    this.vehicle.setDatas(vehiclePayload);

    if (this.isSignUpView()) {
      this.vehicleChange.emit({ vehicle: this.vehicle });
    }

    return true;
  }

  public getSelectedBrand(): CGdsMarqueRow | null {
    return this.brands.find((brand) => brand.MAR_ID === this.vehicleForm?.value.brandId);
  }

  public getSelectedEnergy(): CGdsEnergieRow | null {
    return this.energies.find((energy) => energy.ENE_ID === this.customVehicleForm?.value.energy);
  }

  public getSelectedVehicleType(): CGdsTypeVehiculeRow | null {
    return this.vehicleTypes.find((type) => type.TVH_ID === this.customVehicleForm?.value.type);
  }

  public getSelectedPrestige(): CGdsNiveauPrestigeRow | null {
    return this.prestiges.find(
      (prestige) => prestige.NPR_ID === this.customVehicleForm?.value.prestige,
    );
  }

  public presentBrandSelectionAlert(): void {
    this.presentSelectionAlert({
      items        : this.brands,
      selectedValue: this.vehicleForm?.value.brandId,
      displayField : 'MAR_LIBELLE',
      valueField   : 'MAR_ID',
      onDismiss    : (data) => {
        this.vehicleForm.controls.brandId.setValue(data);
        this.loadModels(data);
        this.cdf.detectChanges();
      },
    });
  }

  public presentEnergySelectionAlert(): void {
    this.presentSelectionAlert({
      items        : this.energies,
      selectedValue: this.customVehicleForm.value.energy,
      displayField : 'ENE_LIBELLE',
      valueField   : 'ENE_ID',
      onDismiss    : (data) => {
        this.customVehicleForm.controls.energy.setValue(data);
        this.cdf.detectChanges();
      },
    });
  }

  public presentVehicleTypeSelectionAlert(): void {
    this.presentSelectionAlert({
      items        : this.vehicleTypes,
      selectedValue: this.customVehicleForm.value.type,
      displayField : 'TVH_LIBELLE',
      valueField   : 'TVH_ID',
      onDismiss    : (data) => {
        this.customVehicleForm.controls.type.setValue(data);
        this.cdf.detectChanges();
      },
    });
  }

  public presentPrestigeSelectionAlert(): void {
    this.presentSelectionAlert({
      items        : this.prestiges,
      selectedValue: this.customVehicleForm.value.prestige,
      displayField : 'NPR_LIBELLE',
      valueField   : 'NPR_ID',
      onDismiss    : (data) => {
        this.customVehicleForm.controls.prestige.setValue(data);
        this.cdf.detectChanges();
      },
    });
  }

  public presentMaxPassengersAmountSelectionAlert(): void {
    this.presentSelectionAlert({
      items: Array.from({ length: MAX_PASSENGERS }, (_, index) => ({
        label: index + 1,
        value: index + 1,
      })),
      selectedValue: this.customVehicleForm.value.maxPassenger,
      displayField : 'label',
      valueField   : 'value',
      translate    : false,
      onDismiss    : (data) => {
        this.customVehicleForm.controls.maxPassenger.setValue(data);
        this.cdf.detectChanges();
      },
    });
  }

  public presentMaxLuggagesAmountSelectionAlert(): void {
    this.presentSelectionAlert({
      items: Array.from({ length: MAX_LUGGAGES }, (_, index) => ({
        label: index + 1,
        value: index + 1,
      })),
      selectedValue: this.customVehicleForm.value.maxLuggages,
      displayField : 'label',
      valueField   : 'value',
      translate    : false,
      onDismiss    : (data) => {
        this.customVehicleForm.controls.maxLuggages.setValue(data);
        this.cdf.detectChanges();
      },
    });
  }

  private async presentSelectionAlert<T>({
    items,
    selectedValue,
    displayField,
    valueField,
    onDismiss,
    translate = true,
  }: {
    items: T[];
    selectedValue: any;
    displayField: string;
    valueField: string;
    onDismiss: (data: any) => void;
    translate?: boolean;
  }): Promise<void> {
    const modal = await this.modalController.create({
      component     : GenericPickerModalComponent,
      cssClass      : 'custom-modal',
      canDismiss    : true,
      componentProps: {
        items,
        selected: selectedValue,
        displayField,
        valueField,
        translate,
      },
    });

    modal.onWillDismiss().then(({ data }) => {
      if (!data) return;
      onDismiss(data);
    });

    await modal.present();
  }

  public isSignUpView(): boolean {
    return this.mode === VehicleFormMode.SIGNUP;
  }
}
