import npmPackage from '../../package.json';

export const environment = {
  production: false, // if true enable serviceWorker that create infinite reloading on local developpement
  name      : 'stage',
  apiRootUrl: {
    geolocalisation   : 'https://stage-gdspartner.waynium.net',
    evenementChauffeur: 'https://stage-gdspartner.waynium.net',
    divers            : 'https://stage-gdspartner.waynium.net',
    flight            : 'https://stage-flight.waynium.net',
    timePrediction    : 'https://stage-predict.waynium.net'
  },
  npmPackageVersion: npmPackage.version,
  npmName          : npmPackage.name,
  firebaseConfig   : {
    apiKey           : "AIzaSyC8jED2m8rbbCyY0CyCMNrCyd_mJL_ALRs",
    authDomain       : "way-partner-stage.firebaseapp.com",
    projectId        : "way-partner-stage",
    storageBucket    : "way-partner-stage.firebasestorage.app",
    messagingSenderId: "584744011857",
    appId            : "1:584744011857:web:d5a915a1c3b7c3be119070"
  }
};
