import { Component, Input } from '@angular/core';

@Component({
  selector   : 'badge-counter',
  templateUrl: './badge-counter.component.html',
  styleUrls  : ['./badge-counter.component.scss'],
})
export class BadgeCounterComponent {
  @Input() counter: number = 0;
}
