<ion-tab-bar slot="bottom" *ngIf="items.length && showTabs()">
  <ion-tab-button *ngFor="let item of items"
                  [routerLinkActive]="'tab-selected'"
                  [routerLink]="item.route"
                  [ngClass]="item.class"
                  layout="label-hide">
    <remix-icon class="size-lg" icon="{{ item.icon }}"></remix-icon>
    <ion-label>{{ item.label }}</ion-label>
    <ion-badge color="danger" *ngIf="item.liste && item.liste.length>0">{{ item.liste.length }}</ion-badge>
  </ion-tab-button>
</ion-tab-bar>