import { Component, Input } from '@angular/core';
import { CGenMissionRow } from '@way-lib/models/Gen/CGenMissionRow';

@Component({
  selector   : 'status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls  : ['./status-badge.component.scss'],
})
export class StatusBadgeComponent {
  @Input() mission: CGenMissionRow;
}
