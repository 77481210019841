import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';

export enum ErrorStatusCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  GONE = 410,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
}

export type GdsError = {
  statusCode: ErrorStatusCode;
  message: string;
  errors: string;
  timestamp: string;
  path: string;
  field: string;
};

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private translate: TranslateService, private toastController: ToastController) {}

  handleError(error: GdsError): void {
    let errorMessage = 'Une erreur est survenue, veuillez réessayer plus tard';

    switch (error.statusCode) {
      case ErrorStatusCode.BAD_REQUEST:
        errorMessage = 'Requête invalide, veuillez vérifier vos données';
        break;
      case ErrorStatusCode.UNAUTHORIZED:
        errorMessage = 'Vous devez vous authentifier pour accéder à cette ressource';
        break;
      case ErrorStatusCode.FORBIDDEN:
        errorMessage = "Vous n'avez pas les droits nécessaires pour accéder à cette ressource";
        break;
      case ErrorStatusCode.NOT_FOUND:
        errorMessage = 'La ressource demandée est introuvable';
        break;
      case ErrorStatusCode.CONFLICT:
        errorMessage = 'Conflit détecté, veuillez réessayer';

        if (error?.field === 'INS_EMAIL') {
          errorMessage = 'Cet email est déjà utilisé pour un autre compte';
        }

        break;
      case ErrorStatusCode.GONE:
        errorMessage = "La ressource demandée n'est plus disponible";
        break;
      case ErrorStatusCode.TOO_MANY_REQUESTS:
        errorMessage = 'Trop de requêtes envoyées en peu de temps, veuillez réessayer plus tard';
        break;
      case ErrorStatusCode.INTERNAL_SERVER_ERROR:
        errorMessage = 'Erreur interne du serveur, veuillez réessayer plus tard';
        break;
      case ErrorStatusCode.BAD_GATEWAY:
        errorMessage = 'Serveur actuellement inaccessible, veuillez réessayer plus tard';
        break;
      case ErrorStatusCode.SERVICE_UNAVAILABLE:
        errorMessage = 'Serveur en cours de maintenance, veuillez réessayer plus tard';
        break;
      case ErrorStatusCode.GATEWAY_TIMEOUT:
        errorMessage = 'La requête a expiré, veuillez réessayer plus tard';
        break;
      default:
        break;
    }

    this.toastController
      .create({
        position   : 'top',
        translucent: false,
        message    : this.translate.instant(errorMessage),
        duration   : 3000,
        color      : 'danger',
      })
      .then((toast) => toast.present());
  }
}
