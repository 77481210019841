<div
  class="input-container"
  (click)="toggleClientModal(true)"
  *ngIf="!control?.value; else selectedClient"
>
  <ion-input
    aria-label="client"
    class="disabled"
    [placeholder]="'Chercher un client' | translate"
    [value]="control?.value ? control?.value.CLI_SOCIETE : ''"
  >
    <tabler-icon icon="search" size="md"></tabler-icon>
  </ion-input>
</div>

<ng-template #selectedClient>
  <div class="client selected" (click)="onRemoveClient()">
    <div class="client-info">
      <span class="company-name">{{ control?.value.CLI_SOCIETE }}</span>
      <span class="client-name"
        >{{ control?.value?.cot.COT_PRENOM }} {{ control?.value?.cot.COT_NOM }}</span
      >
    </div>
    <tabler-icon icon="pencil" size="lg" color="d1"></tabler-icon>
  </div>
</ng-template>

<ion-modal
  [id]="id"
  [initialBreakpoint]="modalBreakpoint"
  [breakpoints]="availableBreakpoints"
  mode="ios"
  [isOpen]="showClientModal"
  (willDismiss)="toggleClientModal(false)"
  (ionBreakpointDidChange)="onBreakpointChange($event)"
  #clientModal
  cssClass="client-modal"
>
  <ng-template>
    <!-- CLIENT LIST -->
    <div class="client-modal-content" *ngIf="!showClientForm; else clientFormElement">
      <div class="client-modal-head">
        <h3 class="head-title">
          {{ 'Client' | translate }}
        </h3>
        <div class="input-container">
          <ion-input
            aria-label="client"
            [placeholder]="'Chercher un client...' | translate"
            [(ngModel)]="keyword"
          >
            <tabler-icon slot="start" icon="search" size="md"></tabler-icon>
          </ion-input>
        </div>
      </div>
      <ion-content
        class="client-list"
        [ngClass]="{ full: modalBreakpoint !== PARTIAL_SCREEN_BREAKPOINT }"
      >
        <ion-list (touchmove)="onListScroll($event)">
          <ng-container *ngFor="let client of clients | ClientFilter : keyword">
            <div class="client" (click)="onSelectClient(client)">
              <tabler-icon icon="user" size="sm"></tabler-icon>
              <div class="client-info">
                <span class="company-name">{{ client.CLI_SOCIETE }}</span>
                <span class="client-name"
                  >{{ client?.cot.COT_PRENOM }} {{ client?.cot.COT_NOM }}</span
                >
              </div>
            </div>
          </ng-container>
        </ion-list>
      </ion-content>
      <ion-button class="add-client-btn white" (click)="toggleClientForm(true)"
        ><span>+</span>{{ 'Ajouter un nouveau client' | translate }}</ion-button
      >
    </div>
    <!-- CLIENT FORM -->
    <ng-template #clientFormElement>
      <div class="client-modal-content client-form" *ngIf="!submitting; else loadingScreen">
        <div
          class="new-client-modal-head flex flex-col items-center"
          *ngIf="newClientFound; else standardHeader"
        >
          <h3 class="head-title dark">
            {{ 'Nouveau client détecté' | translate }}
          </h3>
          <p class="subtitle dark">
            {{
              'Le client de la mission ne semble pas être dans vos contacts. Merci de vérifier les informations.'
                | translate
            }}
          </p>
        </div>
        <ng-template #standardHeader>
          <h3 class="head-title mb-xl">
            {{ 'Ajouter un nouveau client' | translate }}
          </h3>
        </ng-template>
        <ion-content>
          <form [formGroup]="clientForm" *ngIf="clientForm">
            <div>
              <div
                class="input-container"
                [ngClass]="{ 'has-error': submitted && !clientForm.get('company').value }"
              >
                <ion-input
                  formControlName="company"
                  [placeholder]="'Société / Nom de facturation' | translate"
                >
                </ion-input>
              </div>
              <p class="form-error" *ngIf="submitted && !clientForm.get('company').value">
                {{ 'Veuillez entrer un nom de société.' | translate }}
              </p>
            </div>
            <div class="input-container" (click)="civilitySelect.open()">
              <ion-select
                #civilitySelect
                formControlName="civility"
                [placeholder]="'Civilité' | translate"
              >
                <ion-select-option *ngFor="let civ of civilities" [value]="civ.CIV_ID">{{
                  civ.CIV_LIBELLE_LONG | jafTranslate
                }}</ion-select-option>
              </ion-select>
              <span class="input-value">{{
                clientForm.get('civility').value
                  ? (getCivilityLabel(clientForm.get('civility').value) | jafTranslate)
                  : ('Civilité' | translate)
              }}</span>
              <tabler-icon icon="chevron-right" size="md"></tabler-icon>
            </div>
            <div class="client-name-container">
              <div class="input-container">
                <ion-input formControlName="lastName" [placeholder]="'Nom' | translate">
                </ion-input>
              </div>
              <div class="input-container">
                <ion-input formControlName="firstName" [placeholder]="'Prénom' | translate">
                </ion-input>
              </div>
            </div>
            <div class="input-container" (click)="langSelect.open()">
              <ion-select #langSelect formControlName="lang" [placeholder]="'Langue' | translate">
                <ion-select-option [value]="CGenContactRow.LAN_FRENCH">{{
                  'Français' | translate
                }}</ion-select-option>
                <ion-select-option [value]="CGenContactRow.LAN_ENGLISH">{{
                  'Anglais' | translate
                }}</ion-select-option>
              </ion-select>
              <span class="input-value">{{
                clientForm.get('lang').value
                  ? (getLangLabel(clientForm.get('lang').value) | translate)
                  : ('Langue' | translate)
              }}</span>
              <tabler-icon icon="chevron-right" size="md"></tabler-icon>
            </div>
            <div class="input-container">
              <ion-input
                type="email"
                formControlName="email"
                [placeholder]="'Adresse e-mail' | translate"
              >
              </ion-input>
            </div>

            <way-input-mobile
              (phoneData)="clientForm.get('mobile').setValue($event.internationalFormat)"
              [phoneNumber]="clientForm.get('mobile').value"
              [size]="'small'"
              [placeholder]="'N° de téléphone mobile'"
            ></way-input-mobile>

            <way-input-mobile
              (phoneData)="clientForm.get('phone').setValue($event.internationalFormat)"
              [phoneNumber]="clientForm.get('phone').value"
              [size]="'small'"
              [placeholder]="'N° de téléphone fixe'"
            ></way-input-mobile>

            <div class="input-container">
              <ion-input
                formControlName="invoicingAddress"
                [placeholder]="'Adresse de facturation' | translate"
              >
              </ion-input>
            </div>
          </form>
        </ion-content>
        <div class="action-btns">
          <ion-button (click)="onAddClient()">{{ 'Ajouter le client' | translate }}</ion-button>
          <ion-button *ngIf="newClientFound" class="m1" (click)="toggleClientModal(false)">{{
            "C'est une erreur, le client existe déjà" | translate
          }}</ion-button>
        </div>
      </div>
      <ng-template #loadingScreen>
        <div class="loading-screen">
          <loader></loader>
        </div>
      </ng-template>
    </ng-template>
  </ng-template>
</ion-modal>
