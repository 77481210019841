<div
  class="microphone-icon-container pulse-animation"
  [ngClass]="recording ? 'quick-waves' : 'slow-waves'"
  (click)="toggleRecognition()"
>
  <span class="pulse-animation-inner"></span>
  <img
    class="icon"
    [ngClass]="{ active: recording }"
    [src]="
      recording
        ? '../../../assets/icons/custom/ai-microphone-stop.png'
        : '../../../assets/icons/custom/ai-microphone.png'
    "
    alt="ai icon"
  />
</div>
