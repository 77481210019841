import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PriceType } from '@way-lib/models/Com/CComFraisMission';

@Component({
  templateUrl: './price-type-picker.component.html',
  styleUrls  : ['./price-type-picker.component.scss'],
})
export class PriceTypePickerModalComponent {
  @Input() selected: PriceType;

  public PriceType = PriceType;

  constructor(public modalController: ModalController) {}

  public selectPriceType(priceType: PriceType): void {
    this.modalController.dismiss(priceType);
  }
}
