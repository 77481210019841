<div class="price-type-list">
  <div class="price-type" (click)="selectPriceType(PriceType.TTC)">
    <span>{{ 'Tarif TTC' | translate }}</span>
    <tabler-icon *ngIf="selected === PriceType.TTC" icon="check" size="md" color="success"></tabler-icon>
  </div>
  <div class="price-type" (click)="selectPriceType(PriceType.HT)">
    <span>{{ 'Tarif HT' | translate }}</span>
    <tabler-icon *ngIf="selected === PriceType.HT" icon="check" size="md" color="success"></tabler-icon>
  </div>
</div>
