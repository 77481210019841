import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { SubscriptionsService } from '@way-lib/common/services/subscriptions.service';
import { CGenOptionclientRow } from '@way-lib-jaf/rowLoader';

enum WarningType {
  SUB_FIFTEEN_DAYS = 'SUB_FIFTEEN_DAYS',
  SUB_FIVE_DAYS = 'SUB_FIVE_DAYS',
  SUB_ONE_DAY = 'SUB_ONE_DAY',
}

@Component({
  selector   : 'way-partner-subscription-banner',
  templateUrl: './subscription-banner.component.html',
  styleUrls  : ['./subscription-banner.component.scss'],
})
export class SubscriptionBannerComponent implements OnInit {
  public showBanner: boolean = false;

  public daysLeft: number;

  constructor(
    private subscriptionsService: SubscriptionsService,
    private router: Router,
    private storage: Storage,
  ) {}

  ngOnInit() {
    this.subscriptionsService.subscriptions$.subscribe(async (subscriptions) => {
      if (!subscriptions || !subscriptions.length) return;

      const latestSubscription = subscriptions[0];
      const endDate            = new Date(latestSubscription.OPI_FIN);
      const today              = new Date();
      this._resetTime(endDate);
      this._resetTime(today);

      if (endDate < today || latestSubscription.OPI_OPT_ID !== CGenOptionclientRow.OPT_FREE) return;

      this.daysLeft = this._calculateDaysLeft(endDate, today);
      this._showWarningIfNeeded();
    });
  }

  private _resetTime(date: Date) {
    date.setHours(0, 0, 0, 0);
  }

  private _calculateDaysLeft(endDate: Date, today: Date): number {
    const daysLeftInMs = endDate.getTime() - today.getTime();
    return Math.ceil(daysLeftInMs / (1000 * 60 * 60 * 24));
  }

  private async _showWarningIfNeeded() {
    const lastWarning = await this._lastWarningClosed();

    const handleWarning = (warningType: WarningType) => {
      if (!lastWarning || lastWarning !== warningType) {
        this.showBanner = true;
        this._saveLastWarning(warningType);
      }
    };

    if (this.daysLeft <= 15 && this.daysLeft > 5) {
      handleWarning(WarningType.SUB_FIFTEEN_DAYS);
    }

    if (this.daysLeft <= 5 && this.daysLeft > 1) {
      handleWarning(WarningType.SUB_FIVE_DAYS);
    }

    if (this.daysLeft === 1) {
      handleWarning(WarningType.SUB_ONE_DAY);
    }

    if (this.daysLeft === 0) {
      this.showBanner = true;
    }
  }

  public toggleBanner() {
    this.showBanner = !this.showBanner;
  }

  public goToSubscription() {
    this.toggleBanner();
    this.router.navigate(['/parameters']);
  }

  private _saveLastWarning(warningType: WarningType) {
    this.storage.set('lastWarning', warningType);
  }

  private async _lastWarningClosed(): Promise<WarningType> {
    const lastWarning = await this.storage.get('lastWarning');
    return lastWarning;
  }
}
