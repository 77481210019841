import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IonModal } from '@ionic/angular';
import { ConceptManager } from '@way-lib/common/services/jaf/concept-manager';
import { CGenCiviliteRow } from '@way-lib/models/Gen/CGenCiviliteRow';
import { CGenClientRow } from '@way-lib/models/Gen/CGenClientRow';
import { CGenContactRow } from '@way-lib/models/Gen/CGenContactRow';

@Component({
  selector   : 'way-partner-client-picker',
  templateUrl: './client-picker.component.html',
  styleUrls  : ['./client-picker.component.scss'],
})
export class ClientPickerComponent implements OnInit {
  @ViewChild('clientModal') clientModal: IonModal;

  @Input() id: string;

  @Input() control: FormControl;

  public FULL_SCREEN_BREAKPOINT = 1;

  public PARTIAL_SCREEN_BREAKPOINT = 0.75;

  public clientForm: FormGroup;

  public showClientModal = false;

  public showClientForm = false;

  public submitted = false;

  public submitting = false;

  public clients: CGenClientRow[] = [];

  public civilities: CGenCiviliteRow[] = [];

  public keyword: string;

  public modalBreakpoint = this.PARTIAL_SCREEN_BREAKPOINT;

  public CGenContactRow = CGenContactRow;

  constructor(private cm: ConceptManager, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.cm.setOnLoad(() => {
      this.refreshClients();
      this.civilities = this.cm.getConcept('C_Gen_Civilite').all;
    });
  }

  toggleClientModal(state: boolean): void {
    this.showClientModal = state;
    if (!state) {
      this.showClientForm = false;
    }
    this.updateBreakpoint(state ? this.PARTIAL_SCREEN_BREAKPOINT : 0);
  }

  toggleClientForm(state: boolean): void {
    this.showClientForm = state;
    this.updateBreakpoint(state ? this.FULL_SCREEN_BREAKPOINT : this.PARTIAL_SCREEN_BREAKPOINT);

    if (!this.clientForm) {
      this.initClientForm();
    }
  }

  initClientForm(): void {
    this.clientForm = this.formBuilder.group({
      company         : ['', Validators.required],
      civility        : [''],
      firstName       : [''],
      lastName        : [''],
      lang            : [''],
      email           : [''],
      mobile          : [''],
      phone           : [''],
      invoicingAddress: [''],
    });
  }

  onSelectClient(client: CGenClientRow): void {
    this.control.setValue(client);
    this.toggleClientModal(false);
  }

  onRemoveClient(): void {
    this.control.setValue(null);
    this.toggleClientModal(true);
  }

  onAddClient(): void {
    this.submitted  = true;
    this.submitting = true;

    const { company, civility, firstName, lastName, lang, email, mobile, phone } =
      this.clientForm.value;

    if (!company && (firstName || lastName)) {
      const companyName = `${firstName ?? ''} ${lastName ?? ''}`.trim();
      this.clientForm.controls.company.setValue(companyName);
      this.clientForm.updateValueAndValidity();
    }

    if (this.clientForm.invalid) {
      this.submitting = false;
      return;
    }

    const client = this.createClient();

    client.setDatas(
      {
        CLI_SOCIETE: this.clientForm.value.company,
      },
      true,
      () => {
        this.submitting = false;
        this.submitted  = false;

        this.toggleClientForm(false);
        this.clientForm.reset();
        this.refreshClients();
      },
    );

    client.cot.setDatas({
      COT_CIV_ID   : civility,
      COT_EMAIL    : email,
      COT_LAN_ID   : lang,
      COT_MOBILE   : mobile,
      COT_TELEPHONE: phone,
      COT_NOM      : lastName,
      COT_PRENOM   : firstName,
    });
  }

  createClient(): CGenClientRow {
    const database = this.cm.getDatabase();
    const client   = new CGenClientRow(this.cm.getConcept('C_Gen_Client'), {}, database);
    client.cot     = new CGenContactRow(
      this.cm.getConcept('C_Gen_Contact'),
      {
        COT_CLI_ID: client,
      },
      database,
    );
    return client;
  }

  refreshClients(): void {
    this.clients = [...this.cm.getConcept('C_Gen_Client').all];
  }

  onBreakpointChange(e: any): void {
    this.modalBreakpoint = e.detail.breakpoint;
  }

  updateBreakpoint(breakpoint: number): void {
    this.modalBreakpoint = breakpoint;
    this.clientModal?.setCurrentBreakpoint(breakpoint);
  }

  getLangLabel(langId: string): string {
    switch (langId) {
      case CGenContactRow.LAN_FRENCH:
        return 'Français';
      case CGenContactRow.LAN_ENGLISH:
        return 'Anglais';
      default:
        return 'Français';
    }
  }

  getCivilityLabel(civilityId: string): string {
    const civility = this.civilities.find((c) => c.CIV_ID === civilityId);
    return civility ? civility.CIV_LIBELLE_LONG : '';
  }

  onListScroll(event: any): void {
    event.stopPropagation();
  }
}
