<div>
  <ion-item lines="none">
    <ion-input
      [type]="type"
      [id]="id"
      [placeholder]="placeholder"
      [maxlength]="maxLength"
      (input)="onInput($event)"
      (blur)="onBlur()"
      [value]="value"
      [disabled]="disabled"
    ></ion-input>
    <ion-icon
      *ngIf="info"
      [title]="info"
      [tooltip]="info"
      name="information-circle"
      slot="end"
    ></ion-icon>
  </ion-item>
  <way-error [error]="error"></way-error>
</div>
