import { JafConcept } from '@way-lib-jaf/concept';

export class CGdsTypeVehicule extends JafConcept {
  public primary = 'TVH_ID';

  protected name = 'nf_gds_typeVehicule';

  protected class = 'C_Gds_TypeVehicule';

  protected rowClass = 'CGdsTypeVehiculeRow';

  protected trigramme = 'TVH';
}
