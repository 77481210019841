<svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" height="18" viewBox="0 0 18 18" [attr.fill]="fill">
  <g clip-path="url(#clip0_21_169700)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4616 7.84604C15.1555 7.84604 14.8621 7.96761 14.6457 8.184C14.4293
      8.40038 14.3077 8.69387 14.3077 8.99989C14.3077 9.30591 14.4293 9.59939 14.6457 9.81578C14.8621 10.0322 15.1555 10.1537
      15.4616 10.1537C15.7676 10.1537 16.0611 10.0322 16.2774 9.81578C16.4938 9.59939 16.6154 9.30591 16.6154 8.99989C16.6154
      8.69387 16.4938 8.40038 16.2774 8.184C16.0611 7.96761 15.7676 7.84604 15.4616 7.84604ZM13.6666 7.20492C14.1426 6.72887
      14.7883 6.46143 15.4616 6.46143C16.1348 6.46143 16.7805 6.72887 17.2565 7.20492C17.7326 7.68098 18 8.32664 18 8.99989C18
      9.67313 17.7326 10.3188 17.2565 10.7949C16.7805 11.2709 16.1348 11.5383 15.4616 11.5383C14.7883 11.5383 14.1426 11.2709
      13.6666 10.7949C13.1905 10.3188 12.9231 9.67313 12.9231 8.99989C12.9231 8.32664 13.1905 7.68098 13.6666 7.20492Z"
      [attr.fill]="fill" />
    <path d="M6.54895 5.74135C6.81932 5.47098 7.25766 5.47098 7.52803 5.74135L10.2973 8.51058C10.5676 8.78094 10.5676 9.21929
            10.2973 9.48965L7.52803 12.2589C7.25766 12.5292 6.81932 12.5292 6.54895 12.2589C6.27859 11.9885 6.27859 11.5502
            6.54895 11.2798L8.13634 9.69242H0.576951C0.1946 9.69242 -0.115356 9.38246 -0.115356 9.00011C-0.115356 8.61776
            0.1946 8.30781 0.576951 8.30781H8.13634L6.54895 6.72042C6.27859 6.45005 6.27859 6.01171 6.54895 5.74135Z"
      [attr.fill]="fill" />
  </g>
  <defs>
    <clipPath id="clip0_21_169700">
      <rect [attr.width]="width" height="18" [attr.fill]="fill" />
    </clipPath>
  </defs>
</svg>