<div class="input-container" [ngClass]="{ 'filled': variant === 'filled' }">
  <form #f="ngForm" [formGroup]="phoneForm">
    <ngx-intl-tel-input
      (ngModelChange)="onChange($event)"
      [cssClass]="['way_mobile_sel', isInvalid ? 'text-danger' : '', size]"
      [preferredCountries]="preferredCountries"
      [enableAutoCountrySelect]="true"
      [enablePlaceholder]="true"
      [customPlaceholder]="placeholder"
      [searchCountryFlag]="true"
      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
      [selectFirstCountry]="false"
      [selectedCountryISO]="CountryISO.France"
      [maxLength]="15"
      [phoneValidation]="true"
      [separateDialCode]="separateDialCode"
      [numberFormat]="PhoneNumberFormat.National"
      name="phone"
      formControlName="phone"
      (countryChange)="onCountryChange($event)"
    >
    </ngx-intl-tel-input>
  </form>
</div>
