<div class="subscription-banner" *ngIf="showBanner">
  <remix-icon (click)="toggleBanner()" color="d1" icon="ri-close-line"></remix-icon>
  <p>
    {{ 'Votre abonnement' | translate }} <span class="text-bold">WAY-PARTNER</span>
    {{ 'se termine' | translate }}
    {{ daysLeft > 0 ? ('dans' | translate) : '' }}
  </p>
  <div class="days-left">
    {{ daysLeft === 0 ? ("aujourd'hui" | translate) : daysLeft + (' jour'| translate) + (daysLeft > 1 ? 's' : '') }}
  </div>
  <div class="btn-group">
    <ion-button (click)="goToSubscription()">
      {{ 'Renouveler mon abonnement' | translate }}
    </ion-button>
  </div>
</div>
