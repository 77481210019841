import { Pipe, PipeTransform } from '@angular/core';
import { Jaf } from '../services/jaf/jaf';
import { WayMode } from '../services/way-mode.service';

@Pipe({
  name: 'jafTranslate',
})
export class JafTranslate implements PipeTransform {
  transform(input: unknown, wayMode?: WayMode): string {
    const result = Jaf.translate(input);
    return `${result}${wayMode === WayMode.WAY_PARTNER ? `_${  wayMode}` : ''}`;
  }
}
