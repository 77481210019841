import { JafRow } from '@way-lib-jaf/row';
import { WayChamp } from '@way-lib/common/decorator/WayChamp';

export class CGdsTypeVehiculeRow extends JafRow {

  static TVH_SEDAN_BERLINE = '8'

  static TVH_VAN = '3'

  static TVH_BUS = '9'

  static TVH_MOTO = '7'
  
  @WayChamp({
    label: '%LAB_GDS_TYPEVEHICULE_ID%',
    class: 'Id',
  })
  public TVH_ID;

  @WayChamp({
    label: '%LAB_GDS_TYPEVEHICULE_LIBELLE%',
    class: 'Libelle',
  })
  public TVH_LIBELLE;

  @WayChamp({
    label: '%LAB_GDS_TYPEVEHICULE_DESCRIPTION%',
    class: 'Textarea',
  })
  public TVH_DESCRIPTION;

  @WayChamp({
    label: '%LAB_GDS_TYPEVEHICULE_CAPACITE%',
    class: 'Quantite',
  })
  public TVH_CAPACITE;

  @WayChamp({
    label: '%LAB_GDS_TYPEVEHICULE_TRI%',
    class: 'Id',
  })
  public TVH_TRI;
}
