import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WayLibModule } from '@way-lib/way-lib.module';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { LocationPickerComponent } from './location-picker/location-picker.component';
import { DestinationListComponent } from './destination-list/destination-list.component';
import { DestinationListItemComponent } from './destination-list-item/destination-list-item.component';
import { PlaceAutocompleteComponent } from './place-autocomplete/place-autocomplete.component';
import { ClientPickerComponent } from './client-picker/client-picker.component';
import { StepperInputComponent } from './stepper-input/stepper-input.component';
import { QuotedVehiclePickerModalComponent } from './quoted-vehicle-picker/quoted-vehicle-picker.component';
import { PriceTypePickerModalComponent } from './price-type-picker/price-type-picker.component';
import { FilterLanguagesPipe } from '../pipes/lang.pipe';
import { ClientFilterPipe } from '../pipes/client.pipe';
import { TimeFormatPipe } from '../pipes/time-format.pipe';
import { CircleProgressBarComponent } from './circle-progress-bar/circle-progress-bar.component';
import { VehicleModelFilterPipe } from '../pages/vehicle/pipes/vehicle-model.pipe';
import { GenericPickerModalComponent } from './generic-picker-modal/generic-picker-modal.component';
import { MissionTicketComponent } from './mission-ticket/mission-ticket.component';
import { BadgeCounterComponent } from './badge-counter/badge-counter.component';
import { StatusBadgeComponent } from './status-badge/status-badge.component';
import { VehicleFormComponent } from './vehicle-form/vehicle-form.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    WayLibModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    Nl2BrPipeModule,
  ],
  declarations: [
    DatePickerComponent,
    TimePickerComponent,
    LocationPickerComponent,
    DestinationListComponent,
    DestinationListItemComponent,
    PlaceAutocompleteComponent,
    ClientPickerComponent,
    ClientFilterPipe,
    StepperInputComponent,
    QuotedVehiclePickerModalComponent,
    PriceTypePickerModalComponent,
    TimeFormatPipe,
    CircleProgressBarComponent,
    FilterLanguagesPipe,
    VehicleModelFilterPipe,
    GenericPickerModalComponent,
    VehicleFormComponent,
    MissionTicketComponent,
    BadgeCounterComponent,
    StatusBadgeComponent,
  ],
  exports: [
    DatePickerComponent,
    TimePickerComponent,
    LocationPickerComponent,
    DestinationListComponent,
    DestinationListItemComponent,
    PlaceAutocompleteComponent,
    ClientPickerComponent,
    CommonModule,
    IonicModule,
    TranslateModule,
    WayLibModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    Nl2BrPipeModule,
    ClientFilterPipe,
    StepperInputComponent,
    QuotedVehiclePickerModalComponent,
    PriceTypePickerModalComponent,
    TimeFormatPipe,
    CircleProgressBarComponent,
    FilterLanguagesPipe,
    VehicleModelFilterPipe,
    MissionTicketComponent,
    BadgeCounterComponent,
    StatusBadgeComponent,
    VehicleFormComponent
  ],
})
export class SharedModule {}
