<div
  *ngIf="id"
  class="input-container"
  [ngClass]="{ 'has-label variant-signup': showLabel, 'has-error': showError }"
  (click)="toggleCalendarModal(true)"
>
  <span *ngIf="showLabel && control?.value" class="input-label fade-in-bottom-2">{{
    placeholder | translate
  }}</span>
  <tabler-icon icon="calendar-event" size="sm"></tabler-icon>
  <ion-datetime-button [datetime]="id"></ion-datetime-button>
  <ion-modal
    [keepContentsMounted]="true"
    [isOpen]="showCalendar"
    (didDismiss)="toggleCalendarModal(false)"
  >
    <ng-template>
      <ion-datetime
        [firstDayOfWeek]="1"
        [id]="id"
        locale="fr-FR"
        [presentation]="presentation"
        (ionChange)="onSelectDate($event)"
        (ionBlur)="onBlur()"
        [showDefaultButtons]="true"
        [doneText]="'Confirmer' | translate"
        [cancelText]="'Annuler' | translate"
        [max]="maxDate"
        [min]="minDate"
      ></ion-datetime>
    </ng-template>
  </ion-modal>
  <span class="input-value">{{
    control?.value
      ? getFormattedDate(control.value)
      : placeholder
      ? placeholder
      : ('Date' | translate)
  }}</span>
  <tabler-icon icon="chevron-down" size="md"></tabler-icon>
</div>
