import {
  Component,
  Output,
  Input,
  EventEmitter,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CGeoLieuRow } from '@way-lib-jaf/rowLoader';
import { PlaceAutocompleteService } from './place-autocomplete.service';

@Component({
  selector   : 'way-place-autocomplete',
  templateUrl: './place-autocomplete.component.html',
  styleUrls  : ['./place-autocomplete.component.scss'],
  providers  : [PlaceAutocompleteService],
})
export class PlaceAutocompleteComponent implements OnInit, OnDestroy {
  public predictions = [];

  @Output() foundEvents: EventEmitter<CGeoLieuRow[]> = new EventEmitter<CGeoLieuRow[]>();

  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() triggerAPIQuery!: boolean;

  @Input() errorMsg!: string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('formGroup') set setFormGroup(formGroup: FormGroup) {
    this.formGroup = formGroup;
    if (!this.formGroup) {
      this.formGroup = this.formBuilder.group({
        address: ['', [Validators.required]],
      });
    }
    this.formGroup.valueChanges
      .pipe(takeUntil(this._onDestroy), debounceTime(2000))
      .subscribe(() => this.getPredictions());

    this.formGroup.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.loadingPredictions = this._shouldRequestPredictions();
    });
  }

  formGroup: FormGroup;

  loadingPredictions: boolean = false;

  public _onDestroy: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private placeAutocompleteService: PlaceAutocompleteService,
    private cdf: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.placeAutocompleteService.foundLocations.subscribe((locations) => {
      this.loadingPredictions    = false;
      const sanitizedPredictions = this.getSanitizedPlaces(locations);
      if (!sanitizedPredictions.length) {
        this.errorMsg = 'Aucun résultat trouvé.';
      } else {
        this.errorMsg = '';
      }
      this.foundEvents.emit(sanitizedPredictions);
    });
  }

  onSearch(query: string) {
    this.searchChange.emit(query);
  }

  getPredictions() {
    if (this._shouldRequestPredictions()) {
      this.loadingPredictions = true;
      this.cdf.detectChanges();
      this.placeAutocompleteService.getLocation(this.formGroup.value.address);
    }
  }

  /**
   *
   * @param locations Places returns from /wayp/get-lieu-by-address
   * @returns An array of places without duplicates
   */
  getSanitizedPlaces(locations: CGeoLieuRow[]): CGeoLieuRow[] {
    return locations.reduce(
      // eslint-disable-next-line no-return-assign
      (acc, p) => (
        // eslint-disable-next-line no-sequences
        !acc.map[p.LIE_FORMATED] ? ((acc.map[p.LIE_FORMATED] = true), acc.result.push(p)) : null,
        acc
      ),
      { map: {}, result: [] },
    ).result;
  }

  private _shouldRequestPredictions(): boolean {
    return (
      this.triggerAPIQuery && this.formGroup.value.address && this.formGroup.value.address.length
    );
  }

  _triggerOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnDestroy() {
    this._triggerOnDestroy();
  }
}
