import { Component, Input } from '@angular/core';
import { CGeoLieuRow } from '@way-lib-jaf/rowLoader';

@Component({
  selector   : 'destination-list-item',
  templateUrl: './destination-list-item.component.html',
  styleUrls  : ['./destination-list-item.component.scss'],
})
export class DestinationListItemComponent {
  @Input() lieu: CGeoLieuRow;

  icons = ['ri-plane-line', 'ri-train-line', 'ri-compass-discover-fill', 'ri-question-mark'];

  get icon() {
    if (!this.lieu){
      return this.icons[2];
    }
    const typeId = this.lieu.LIE_TLI_ID ?? 4;
    return this.icons[typeId - 1];
  }
}
