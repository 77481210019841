<ion-row class="range-container">
  <ion-range
    [ngClass]="{ disabled: isDisabled }"
    class="ion-no-padding"
    mode="ios"
    (ionInput)="onRangeChange($event)"
    [formControl]="control"
    [min]="PRICE_RANGE_CONSTANTS.MIN_VALUE"
    [max]="PRICE_RANGE_CONSTANTS.MAX_VALUE"
    [step]="PRICE_RANGE_CONSTANTS.STEP_SIZE"
  ></ion-range>
  <div class="custom-tick"></div>
  <div class="custom-tick"></div>
</ion-row>
