import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WayLibModule } from '@way-lib/way-lib.module';
import { MenuComponent } from './menu/menu.component';
import { SharedModule } from './shared.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    WayLibModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    Nl2BrPipeModule,
    SharedModule,
  ],
  declarations: [MenuComponent],
  exports     : [MenuComponent],
})
export class WayPartnerCommonModule {}
