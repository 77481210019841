<ion-text class="title" *ngIf="vehicle.VOI_MODELE && !vehicle.VOI_MOE_ID">{{
  vehicle.VOI_MODELE
}}</ion-text>
<ion-text class="title" *ngIf="vehicle.VOI_MOE_ID && vehicle.VOI_MOE_ID.MOE_MAR_ID">
  {{ vehicle.VOI_MOE_ID.MOE_MAR_ID?.MAR_LIBELLE }}
</ion-text>
<ion-text class="subtitle" color="medium" *ngIf="vehicle.VOI_MOE_ID">{{
  vehicle.VOI_MOE_ID?.label
}}</ion-text>
<ion-text
  class="subtitle"
  color="medium"
  *ngIf="vehicle.VOI_TVE_ID && (vehicle.VOI_TVE_ID.TVE_LIBELLE | jafTranslate)"
>
  {{ vehicle.VOI_TVE_ID.TVE_LIBELLE | jafTranslate }}
</ion-text>
<ion-text class="licence-plate">{{ vehicle.VOI_LIBELLE }}</ion-text>
