import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterLanguages',
})
export class FilterLanguagesPipe implements PipeTransform {
  transform(languages: any[], searchText: string): any[] {
    if (!languages) return [];
    if (!searchText) return languages;

    return languages.filter((language) =>
      language.CCH_LIBELLE.toLowerCase().includes(searchText.toLowerCase()),
    );
  }
}
