<div class="auto-complete-container">
  <div [formGroup]="formGroup" class="input-wrapper ion-no-padding ion-margin-bottom">
    <div class="input-container">
      <ion-input
        [placeholder]="'Entrez une adresse...' | translate"
        formControlName="address"
        (ionChange)="onSearch($event.target.value)"
      >
        <div class="small-loader" *ngIf="loadingPredictions; else showSearchIcon">
          <loader [width]="'15px'" [height]="'15px'"></loader>
        </div>
        <ng-template #showSearchIcon>
          <tabler-icon icon="search" size="sm"></tabler-icon></ng-template
      ></ion-input>
    </div>
  </div>
  <ion-text *ngIf="errorMsg" class="error-msg ion-margin-top">{{ errorMsg | translate }}</ion-text>
</div>
