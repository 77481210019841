import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  GeolocationRequest,
  GeolocationService,
} from '@way-lib/common/services/geolocation.service';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { interval, Subscription, take } from 'rxjs';

const COOLDOWN_DURATION = 5;

@Component({
  selector   : 'geolocation-modal',
  templateUrl: './geolocation-modal.component.html',
  styleUrls  : ['./geolocation-modal.component.scss'],
})
export class GeolocationModalComponent implements OnInit, OnDestroy {
  public showModal = false;

  private subscription: Subscription;

  public requestType: GeolocationRequest = GeolocationRequest.GLOBAL;

  public geolocationMessage = {
    [GeolocationRequest.GLOBAL]: {
      title: 'Permissions de géolocalisation',
      description:
        "WAY-Partner a besoin de votre position exacte pour vous proposer des missions et d'autres fonctionnalités utiles.",
    },
    [GeolocationRequest.WAYPLAN]: {
      title: 'Activer la géolocalisation',
      description:
        "Votre donneur d'ordre a besoin de votre position lorsque vous jouez une mission afin de garantir votre sécurité et celle de vos passagers",
    },
  };

  cooldownActive: boolean = false;

  cooldownTime: number = COOLDOWN_DURATION;

  private cooldownSubscription: Subscription | null = null;

  constructor(
    public geolocationService: GeolocationService,
    private toastController: ToastController,
    private translate: TranslateService,
    private cdf: ChangeDetectorRef,
    private platform: Platform,
  ) {}

  ngOnInit(): void {
    this.subscription = this.geolocationService.presentModal$.subscribe(
      (geolocationRequest: GeolocationRequest) => {
        if (this.showModal) return;

        this.requestType = geolocationRequest;
        this.startCooldown();
        this.showModal = true;
        this.cdf.detectChanges();
      },
    );
  }

  public dismiss(): void {
    if (this.cooldownActive) return;

    this.showModal = false;
    this.cdf.detectChanges();
  }

  public handleActivateGeolocation(): void {
    this.stopCooldown();
    this.dismiss();
    this.requestPermission();
  }

  public async requestPermission(): Promise<void> {
    const hasPermissions = await this.geolocationService.verifyAppPermissions();

    if (!hasPermissions) {
      this.handleGeolocationError();
      return;
    }

    try {
      await this.geolocationService.requestGeolocationStart();

      this.showToast('Géolocalisation activée', 'success');
    } catch (error) {
      this.handleGeolocationError();
    }
  }

  private showError(): void {
    this.showToast(
      "Une erreur est survenue, veuillez vérifier les permissions de l'application et recommencer",
      'danger',
    );
  }

  private handleGeolocationError() {
    if (!this.platform.is('capacitor')) {
      this.showError();
      return;
    }

    try {
      if (this.platform.is('ios')) {
        NativeSettings.openIOS({
          option: IOSSettings.App,
        });
      } else {
        NativeSettings.openAndroid({
          option: AndroidSettings.ApplicationDetails,
        });
      }
    } catch (error) {
      console.log(error);
      this.showError();
    } finally {
      this.cdf.detectChanges();
    }
  }

  private showToast(message: string, type: 'success' | 'danger'): void {
    this.toastController
      .create({
        position   : 'top',
        translucent: false,
        message    : this.translate.instant(message),
        duration   : 3000,
        buttons    : [
          {
            icon: 'close',
            side: 'end',
            role: 'cancel',
          },
        ],
        color: type,
      })
      .then((toast) => toast.present());
  }

  private startCooldown(): void {
    this.cooldownActive = true;
    this.cooldownTime   = COOLDOWN_DURATION;

    this.cooldownSubscription = interval(1000)
      .pipe(take(COOLDOWN_DURATION))
      .subscribe(() => {
        this.cooldownTime--;

        if (this.cooldownTime === 0) {
          this.stopCooldown();
        }

        this.cdf.detectChanges();
      });
  }

  private stopCooldown(): void {
    this.cooldownActive = false;
    this.cooldownSubscription?.unsubscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
