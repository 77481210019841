import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Platform } from '@ionic/angular';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { Router } from '@angular/router';
import { Gds } from './jaf/gds';
import { ConceptManager } from './jaf/concept-manager';
import { InternalNotification, InternalNotificationTemplate } from './jaf/conceptLoader';
import { CGdsEvenementChauffeurRow } from './jaf/rowLoader';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private token: string;

  public firebaseUnsubscribeCallback;

  constructor(
    private gds: Gds,
    private router: Router,
    private platform: Platform,
    private firebase: AngularFireMessaging,
    private cm: ConceptManager,
  ) {}

  onTokenReceived(token): string {
    if (!token) return '';
    this.token = token;
    this.gds.post('divers', '/gdsv3/chauffeur-add-token', { token: this.token });
    return token;
  }

  onTokenError = (error) => {
    console.error('onTokenError', error);
  };

  onMessageReceived = (payload) => {
    const notificationOption = {
      body: payload.notification.body,
      icon: payload.notification.icon,
    };

    if (Notification.permission === 'granted') {
      const notification = new Notification(payload.notification.title, notificationOption);

      notification.onclick = (ev) => {
        ev.preventDefault();
        window.focus();
        notification.close();
      };
    } else {
      this.firebaseUnsubscribeCallback();
    }
  };

  initNavigator() {
    this.firebase.requestToken.subscribe(
      (token) => {
        this.onTokenReceived(token);
      },
      (err) => {
        this.onTokenError(err);
      },
    );
    this.firebase.messages.subscribe(this.onMessageReceived, this.onTokenError);
  }

  async initCordova() {
    await PushNotifications.addListener('registration', (token: Token) => {
      this.onTokenReceived(token.value);
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.debug('Push notification received: ', notification);
      },
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.debug(
          'Push notification action performed',
          notification.actionId,
          notification.inputValue,
        );
        if (notification.notification.data.url) {
          this.router.navigateByUrl(notification.notification.data.url);
        }
      },
    );

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();

    // const notificationList = await PushNotifications.getDeliveredNotifications();
    // console.log('delivered notifications', notificationList);
  }

  init() {
    if (this.platform.is('capacitor')) {
      this.initCordova();
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          this.initNavigator();
        }
      });
    }

    this.verifyRegisterId();
  }

  private verifyRegisterId(): void {
    this.cm.setOnLoad(() => {
      const commercialEntity = this.cm.getConcept('C_Gen_EntiteCommerciale').commercialEntity;

      if (commercialEntity.ECO_IMAT_REGISTRE) return;

      this.createMissingRegisterIdNotification();
    });
  }

  public createMissingRegisterIdNotification(): void {
    const conceptEvc = this.cm.getConcept('C_Gds_EvenementChauffeur');

    if (
      conceptEvc.listeNotification.some((notification) =>
        notification.isInternalNotification(InternalNotification.NOTIFICATION_NO_REGISTER_ID),
      )
    ) {
      return;
    }

    const notificationTemplate: InternalNotificationTemplate | null =
      conceptEvc.getInternalNotificationTemplate(InternalNotification.NOTIFICATION_NO_REGISTER_ID);

    if (!notificationTemplate) return;

    const notification = new CGdsEvenementChauffeurRow(
      this.cm.getConcept('C_Gds_EvenementChauffeur'),
      {
        EVC_DATE  : new Date(),
        EVC_PARAMS: {
          titreNotif : notificationTemplate.titreNotif,
          labelsNotif: notificationTemplate.labelsNotif,
        },
        EVC_FLAG_NOTIFICATION: 1,
        EVC_CPT_CLASS        : 'C_Gen_EntiteCommerciale'
      },
      this.cm.getDatabase(),
    );

    conceptEvc.addInternalNotification(notification);
  }

  public deleteInternalNotification(notificationType: InternalNotification): void {
    this.cm.getConcept('C_Gds_EvenementChauffeur').removeInternalNotification(notificationType);
  }
}
