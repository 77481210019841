import { BehaviorSubject, Observable } from 'rxjs'
import { JafConcept } from '@way-lib-jaf/concept';
import { CGdsDtrRow } from '@way-lib-jaf/rowLoader';

export class CGdsDtr extends JafConcept {
  public primary = 'DTR_ID';

  protected name = 'nf_gds_dtr';

  protected class = 'C_Gds_Dtr';

  protected rowClass = 'CGdsDtrRow';

  protected trigramme = 'DTR';

  private _available$: BehaviorSubject<Array<CGdsDtrRow>> = new BehaviorSubject<Array<CGdsDtrRow>>([]);

  private _available: Array<CGdsDtrRow>;

  get available$(): Observable<Array<CGdsDtrRow>> {
    return this._available$.asObservable();
  }

  get available() {
    if (this._available === undefined) {
      this.build_available();
      this.cm.detectChanges();
    }
    return this._available;
  }

  build_available() {
    if (this._available === undefined) {
      this._available = new Array<CGdsDtrRow>();
      this.bindfilter(['DTR_ID', 'DTR_STD_ID'], ['build_all', 'build_available']);
    }
    this.arrayMerge(
      this._available,
      (<CGdsDtrRow[]>this.all)
        .filter((dtr: CGdsDtrRow) => {
          return (
            +dtr.DTR_DO_INS_ID !== this.cm.getInstallationPrincipal().INS_ID &&
            dtr.DTR_ST_INS_ID === CGdsDtrRow.DTR_AVAILABLE
          );
        })
        .sort((a: CGdsDtrRow, b: CGdsDtrRow) => {
          return a.DTR_DATE_MISSION.getTime() - b.DTR_DATE_MISSION.getTime();
        }),
    );
    this._available$.next(this._available);
  }

  private _noResponse: Array<CGdsDtrRow>;

  get noResponse() {
    if (this._noResponse === undefined) {
      this.build_noResponse();
      this.cm.detectChanges();
    }
    return this._noResponse;
  }

  build_noResponse() {
    if (this._noResponse === undefined) {
      this._noResponse = new Array<CGdsDtrRow>();
      this.bindfilter(['DTR_ID', 'DTR_STD_ID'], ['build_all', 'build_noResponse']);
    }
    this.arrayMerge(
      this._noResponse,
      (<CGdsDtrRow[]>this.all)
        .filter((dtr: CGdsDtrRow) => {
          return (
            +dtr.DTR_DO_INS_ID === this.cm.getInstallationPrincipal().INS_ID &&
            dtr.DTR_ST_INS_ID === CGdsDtrRow.DTR_AVAILABLE && dtr.isInProcess
          );
        })
        .sort((a: CGdsDtrRow, b: CGdsDtrRow) => {
          return a.DTR_DATE_MISSION.getTime() - b.DTR_DATE_MISSION.getTime();
        }),
    );
  }

  private _inProcess: Array<CGdsDtrRow>;

  get inProcess() {
    if (this._inProcess === undefined) {
      this.build_inProcess();
      this.cm.detectChanges();
    }
    return this._inProcess;
  }

  build_inProcess() {
    if (this._inProcess === undefined) {
      this._inProcess = new Array<CGdsDtrRow>();
      this.bindfilter(['DTR_ID', 'DTR_STD_ID'], ['build_all', 'build_inProcess']);
    }
    this.arrayMerge(
      this._inProcess,
      (<CGdsDtrRow[]>this.all)
        .filter((dtr: CGdsDtrRow) => {
          return (
            +dtr.DTR_DO_INS_ID === this.cm.getInstallationPrincipal().INS_ID &&
            dtr.DTR_ST_INS_ID !== CGdsDtrRow.DTR_AVAILABLE && dtr.isInProcess
          );
        })
        .sort((a: CGdsDtrRow, b: CGdsDtrRow) => {
          return a.DTR_DATE_MISSION.getTime() - b.DTR_DATE_MISSION.getTime();
        }),
    );
  }
}
