<ion-segment [value]="segmentValue" mode="md">
  <ion-segment-button *ngFor="let item of items" [routerLink]="item.route" [value]="item.index">
    <ion-label>
      {{ item.label | translate }}
      <ion-badge
        class="flex items-center justify-center"
        color="danger"
        *ngIf="item.count && count(item.count) > 0"
        >{{ count(item.count) }}</ion-badge
      >
    </ion-label>
  </ion-segment-button>
</ion-segment>
