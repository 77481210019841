import { formatDate } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Jaf } from '@way-lib/common/services/jaf/jaf';

@Component({
  selector   : 'way-partner-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls  : ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  @Input() id: string;

  @Input() control: FormControl;

  @Input() placeholder: string;

  @Input() maxDate: string;

  @Input() format = 'dd/MM';

  @Input() presentation: 'date' | 'year' | 'month-year' = 'date';

  @Input() showLabel = false

  @Input() showError = false

  public showCalendar: boolean = false;

  toggleCalendarModal(state: boolean): void {
    this.showCalendar = state;
  }

  onSelectDate(e: any): void {
    this.control.setValue(Jaf.date2mysql(e.detail.value));
  }

  onBlur(): void {
    if (!this.control.value) {
      this.control.setValue(Jaf.date2mysql(new Date()));
    }
  }

  getFormattedDate(date: string): string {
    return formatDate(date, this.format, Jaf.LAN_CODE);
  }
}
