import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ConceptManager } from '@way-lib-jaf/concept-manager'
import { Jaf } from '@way-lib-jaf/jaf'
import { CGeoLieuRow } from '@way-lib-jaf/rowLoader'

@Component({
  selector   : 'way-partner-destination-list',
  templateUrl: './destination-list.component.html',
  styleUrls  : ['./destination-list.component.scss'],
})
export class DestinationListComponent implements OnInit {
  @Output() itemClick = new EventEmitter<CGeoLieuRow>();

  categories: string[] = ['Tout', 'Aéroports', 'Gare', 'Lieu privé'];

  displayedPlaces: Array<CGeoLieuRow> = [];

  registeredPlaces: Array<CGeoLieuRow> = [];

  query: string = '';

  selectedCategory: string = 'Tout';

  triggerAPIQuery: boolean = false;

  errorMsg: string = '';

  formGroup: FormGroup;

  constructor(private cm: ConceptManager, public formBuilder: FormBuilder) {
    this.registeredPlaces = cm.getConcept('C_Geo_Lieu').all;
    this.displayedPlaces  = [...this.registeredPlaces];
  }

  ngOnInit(): void {
    this.initForm();
  }

  onSearchChange(query: string) {
    if (query === '') {
      this.displayedPlaces = [...this.registeredPlaces];
      this.errorMsg        = '';
    }
    this.triggerAPIQuery = false;
    this.query           = Jaf.toUpperCaseSansAccent(query).replaceAll(' ', '');
    const matchingPlace  = this.displayedPlaces.find(
      (place) => this.isMatching(place.LIE_FORMATED) || this.isMatching(place.LIE_LIBELLE),
    );
    if (!matchingPlace && this.query.length < 10) {
      this.errorMsg = 'Veuillez affiner votre recherche..';
    } else if (!matchingPlace && this.query.length >= 10) {
      this.errorMsg        = '';
      this.triggerAPIQuery = true;
    } else if (matchingPlace) {
      this.errorMsg = '';
    }
  }

  onFoundEvents(places: CGeoLieuRow[]) {
    this.displayedPlaces = this.registeredPlaces.concat(places);
  }

  isMatching(string: string): boolean {
    return Jaf.toUpperCaseSansAccent(string)
      .replaceAll(' ', '')
      .replaceAll(',', '')
      .includes(this.query);
  }

  displayPlace(place: CGeoLieuRow) {
    if (this.query === '' && this.selectedCategory === 'Tout') return true;
    if (!(place instanceof CGeoLieuRow)) {
      return true;
    }
    const isSameCategory =
      place.LIE_TLI_ID === this.categories.indexOf(this.selectedCategory).toString() ||
      this.selectedCategory === 'Tout';

    return (
      isSameCategory && (this.isMatching(place.LIE_FORMATED) || this.isMatching(place.LIE_LIBELLE))
    );
  }

  onClick(place: CGeoLieuRow) {
    if (
      !this.registeredPlaces.some(
        (registeredPlace: CGeoLieuRow) => registeredPlace.LIE_ID === place.LIE_ID,
      )
    ) {
      const newPlace = new CGeoLieuRow(this.cm.getConcept('C_Geo_Lieu'), {}, this.cm.getDatabase());
      newPlace.setDatas(place, false);
      this.cm.getConcept('C_Geo_Lieu').setRow(newPlace);
      this.itemClick.emit(newPlace);
    }
    this.formGroup.reset();
    this.itemClick.emit(place);
  }

  selectCategory(category: string) {
    this.selectedCategory = category;
    this.cm.detectChanges();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      address: ['', Validators.required],
    });
  }

  onListScroll(event: any): void {
    event.stopPropagation();
  }
}
