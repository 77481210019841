import { Component, Input } from '@angular/core';
import { CGeoLieuRow } from '@way-lib-jaf/rowLoader';

@Component({
  selector   : 'destination-list-item',
  templateUrl: './destination-list-item.component.html',
  styleUrls  : ['./destination-list-item.component.scss'],
})
export class DestinationListItemComponent {
  @Input() place: CGeoLieuRow;
}
