<ion-header mode="ios">
  <ion-toolbar mode="md">
    <!-- BACK NAVIGATION BUTTON -->
    <ion-buttons *ngIf="showBackButton && !disableMenu" slot="start">
      <ion-button
        class="back-button"
        *ngIf="useCustomNavigation; else defaultBackButton"
        (click)="emitCustomNavigationEvent()"
        ><ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon
      ></ion-button>
      <ng-template #defaultBackButton>
        <ion-back-button [defaultHref]="defaultHrefToString"></ion-back-button>
      </ng-template>
    </ion-buttons>
    <!-- WAY-D SIDE MENU TOGGLE BUTTON -->
    <ion-buttons
      *ngIf="!showBackButton && !disableMenu && wayModeService.getMode() === WayMode.WAY_D"
      slot="start"
    >
      <ion-menu-button auto-hide="false">
        <remix-icon class="size-md" color="{{ color }}" icon="ri-menu-2-fill"></remix-icon>
      </ion-menu-button>
    </ion-buttons>
    <!-- GEOLOCATION AND NOTIFICATIONS BUTTONS -->
    <ion-buttons slot="primary">
      <geolocation-button color="brand" size="md"></geolocation-button>
      <ion-button
        *ngIf="
          !disableMenu &&
          !(
            wayModeService.getMode() === WayMode.WAY_PARTNER &&
            (subscriptionService.hasActiveSubscription$ | async) === false
          )
        "
        [routerLink]="'/notifications'"
      >
        <remix-icon class="size-lg" color="{{ color }}" icon="ri-notification-2-line"></remix-icon>
        <ion-badge *ngIf="conceptEvc.numberNewNotification > 0" color="danger">
          {{ conceptEvc.numberNewNotification }}
        </ion-badge>
      </ion-button>
    </ion-buttons>
    <ion-title mode="md" class="ion-text-center">
      <way-logo [ngClass]="color === 'blue' ? '' : 'primary'">{{ suffix }}</way-logo>
    </ion-title>
  </ion-toolbar>
  <ng-content></ng-content>
</ion-header>
