<ion-row class="range-container">
  <ion-range
    [ngClass]="{ disabled: isDisabled }"
    class="ion-no-padding"
    mode="ios"
    (ionChange)="onRangeChange($event)"
    [formControl]="control"
    [ticks]="true"
    [snaps]="true"
    [min]="0"
    [max]="10"
  ></ion-range>
  <div class="custom-tick"></div>
  <div class="custom-tick"></div>
</ion-row>
