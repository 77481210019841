import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CGeoLieuRow } from '@way-lib/models/Geo/CGeoLieuRow';

@Component({
  selector   : 'way-partner-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls  : ['./location-picker.component.scss'],
})
export class LocationPickerComponent {
  @Input() id: string;

  @Input() control: FormControl;

  public showLocationModal: boolean = false;

  toggleLocationModal(state: boolean): void {
    this.showLocationModal = state;
  }

  onSelectLocation(location: CGeoLieuRow): void {
    this.control.setValue(location);
    this.toggleLocationModal(false);
  }
}
