import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localeTh from '@angular/common/locales/th';
import localeAr from '@angular/common/locales/ar';
import localeHe from '@angular/common/locales/he';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { WayLibModule } from '@way-lib/way-lib.module';
import { HttpLoaderFactory } from '@way-lib/common/services/http-loader.service';
import { AuthGuard } from '@way-lib/common/services/auth/auth.guard';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MyMissingTranslationHandler } from '@way-lib/common/handlers/missing-translation';
import { WayModeService } from '@way-lib/common/services/way-mode.service';
import { MenuService } from '@way-partner/services/menu.service'
import { WayPartnerCommonModule } from './components/common.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { SubscriptionBannerComponent } from './components/subscription-banner/subscription-banner.component';
import { ErrorInterceptor } from './services/error-interceptor';

registerLocaleData(localeFr);
registerLocaleData(localeEs);
registerLocaleData(localeIt);
registerLocaleData(localeNl);
registerLocaleData(localeTh);
registerLocaleData(localeAr);
registerLocaleData(localeHe);

@NgModule({
  declarations: [AppComponent, SubscriptionBannerComponent],
  imports     : [
    IonicModule.forRoot({
      scrollPadding: false,
      scrollAssist : false,
    }),
    IonicStorageModule.forRoot({
      name       : 'way-partner',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB],
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide   : TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps      : [HttpClient],
      },
      missingTranslationHandler: {
        provide : MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
      useDefaultLang: false,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    WayLibModule,
    WayPartnerCommonModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    AngularFireMessagingModule,
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    {
      provide : RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide : 'apiRootUrl',
      useValue: environment.apiRootUrl,
    },
    {
      provide : 'WaySuffix',
      useValue: 'Partner',
    },
    {
      provide : 'disableMenu',
      useValue: true,
    },
    {
      provide : 'menuService',
      useClass: MenuService,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthGuard,
    WayModeService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
