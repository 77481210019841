import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { STORAGE_KEY_GEOLOCATION_ENABLED } from '@way-lib/common/constant';
import { CGeoLieuRow } from '@way-lib-jaf/rowLoader';
import { ConceptManager } from '@way-lib-jaf/concept-manager';
import { Gds } from '@way-lib-jaf/gds';
import { Router } from '@angular/router';

enum PermissionState {
  GRANTED = 'granted',
  PROMPT = 'prompt',
}

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  public backgroundIsReady = false;

  public lastTimeSendLocation = 0;

  public enabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public lastPosition$: BehaviorSubject<Position | null> = new BehaviorSubject<Position | null>(
    null,
  );

  public MIN_DELAY_BETWEEN_LOCATION_SENT: number = 60000;

  public get isMobile(): boolean {
    return this.platform.is('capacitor');
  }

  constructor(
    private platform: Platform,
    private storage: Storage,
    private cm: ConceptManager,
    private gds: Gds,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.storage.create().then(() => {
      this.storage.get(STORAGE_KEY_GEOLOCATION_ENABLED).then((enabled: boolean | null) => {
        const enabledByUser = Boolean(enabled);

        this.enabled$.next(enabledByUser);

        if (enabledByUser) {
          this.requestGeolocationStart();
        }
      });
    });
  }

  public get enabled(): boolean {
    return this.enabled$.value;
  }

  public toggle(): void {
    if (this.enabled) {
      this.disable();
      return;
    }

    this.requestGeolocationStart();
  }

  public async requestGeolocationStart(): Promise<void> {
    const geolocationState = await this.getState();

    if (!geolocationState) {
      this.router.navigate(['/geolocation']);
      return;
    }

    this.watchPosition().subscribe({
      next: (location) => {
        this.lastPosition$.next(location);

        if (this.hasDriverLocationExpired) {
          this.sendDriverPosition(location);
        }
      },
      error: (error: any) => {
        console.error(error);
      },
    });
  }

  private async getState(): Promise<boolean> {
    if (this.isMobile) {
      const pluginState = await BackgroundGeolocation.getState();

      this.setEnable(pluginState.enabled);
      return this.enabled;
    }

    const permissions = await Geolocation.checkPermissions();

    this.setEnable(
      permissions.location === PermissionState.GRANTED ||
        permissions.coarseLocation === PermissionState.GRANTED,
    );
    return this.enabled;
  }

  public watchPosition(): Observable<Position | null> {
    if (this.isMobile) {
      return new Observable<Position | null>((observer) => this.handleMobileGeolocation(observer));
    }

    return new Observable<Position | null>((observer) => this.handleWebGeolocation(observer));
  }

  private handleMobileGeolocation(observer: Observer<Position | null>): void {
    const params = this.gds.getParamsTokenVide();

    const config = {
      reset                        : true,
      debug                        : false,
      logLevel                     : BackgroundGeolocation.LOG_LEVEL_OFF,
      desiredAccuracy              : BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
      backgroundPermissionRationale: {
        title: this.translate.instant(
          "Permettre à {applicationName} d'accéder à votre localisation en arrière plan ?",
        ),
        message: this.translate.instant(
          "Pour reçevoir des missions {applicationName} lorsque vous êtes hors de l'application, vous devez activer les permissions pour {backgroundPermissionOptionLabel} ",
        ),
        positiveAction: this.translate.instant('Changez pour {backgroundPermissionOptionLabel}'),
        negativeAction: this.translate.instant('Annuler'),
      },
      distanceFilter               : 15,
      locationUpdateInterval       : 5000,
      fastestLocationUpdateInterval: 5000,
      desiredOdometerAccuracy      : 10,
      preventSuspend               : true,
      heartbeatInterval            : 60,
      stopOnTerminate              : false,
      startOnBoot                  : true,
      notification                 : {
        title    : this.translate.instant('Service de localisation'),
        text     : this.translate.instant('Le service est actuellement activé'),
        smallIcon: '@drawable/ic_notification', // <-- defaults to app icon
        largeIcon: '@drawable/ic_notification',
      },
      url: this.gds.getUrlRequest(
        'geolocalisation',
        `/geolocation/set-position-chauffeur/${params.ach_id}/${params.token}`,
      ),
    };

    if (this.platform.is('android')) {
      config.distanceFilter = 0;
    }

    this.listenGeolocationEvents(observer);

    BackgroundGeolocation.ready(config)
      .then((state) => {
        BackgroundGeolocation.changePace(true);
        if (!state.enabled) {
          BackgroundGeolocation.start()
            .then((r) => {
              // eslint-disable-next-line no-console
              observer.next(null);

              this.setEnable(r.enabled);
            })
            .catch((error) => {
              observer.error(error);
            });
        }

        this.backgroundIsReady = true;
      })
      .catch((r) => {
        observer.error(r);
      });
  }

  private handleWebGeolocation(observer: Observer<Position>): void {
    const config: PositionOptions = {
      enableHighAccuracy: true,
    };

    Geolocation.watchPosition(config, (position, error: GeolocationPositionError) => {
      if (error) {
        console.error(error.message);
        observer.error(error.message);
        this.setEnable(false);
        return;
      }

      this.setEnable(true);
      observer.next(this.getPositionFormated(position));
    });
  }

  private listenGeolocationEvents(observer: Observer<Position | null>): void {
    BackgroundGeolocation.onLocation(
      (location) => {
        if (!this.enabled) {
          observer.complete();
        }

        this.lastPosition$.next(this.getPositionFormated(location));
      },
      (r) => {
        console.error('erreur onLocation', r);
        observer.error(r);
      },
    );

    BackgroundGeolocation.onHeartbeat(() => {
      this.forceRecuperationPosition(observer);
    });
  }

  public disable(): void {
    if (this.isMobile) {
      BackgroundGeolocation.stop().then(() => {
        this.setEnable(false);
      });
      return;
    }

    this.setEnable(false);
  }

  public stop(): void {
    if (this.enabled && this.backgroundIsReady) {
      BackgroundGeolocation.stop();
    }
  }

  private setEnable(enabled: boolean): void {
    this.enabled$.next(enabled);

    this.updateDriverGeolocationFlag();

    this.storage.set(STORAGE_KEY_GEOLOCATION_ENABLED, this.enabled);
  }

  private sendDriverPosition(location: Position | null): void {
    if (!location) return;

    const request = this.gds.post('geolocalisation', '/geolocation/set-position-chauffeur', {
      location,
    });

    request.subscribe(() => {
      this.lastTimeSendLocation = new Date().getTime();
    });
  }

  private get hasDriverLocationExpired(): boolean {
    return new Date().getTime() - this.lastTimeSendLocation > this.MIN_DELAY_BETWEEN_LOCATION_SENT;
  }

  addGeofence(lie: CGeoLieuRow) {
    BackgroundGeolocation.addGeofence({
      identifier   : lie.LIE_ID,
      radius       : 300,
      latitude     : lie.LIE_LAT,
      longitude    : lie.LIE_LNG,
      notifyOnEntry: true,
      notifyOnExit : true,
    })
      .then(() => {})
      .catch((error) => {
        console.error('[addGeofence] FAILURE: ', error);
      });
  }

  removeGeofence(lie: CGeoLieuRow) {
    BackgroundGeolocation.removeGeofence(lie.LIE_ID).then(() => {
      // console.log('[removeGeofence] success');
    });
  }

  async getCurrentPosition() {
    if (this.backgroundIsReady) {
      return BackgroundGeolocation.getCurrentPosition({
        timeout        : 30, // 30 second timeout to fetch location
        maximumAge     : 5000, // Accept the last-known-location if not older than 5000 ms.
        desiredAccuracy: 100, // Try to fetch a location with an accuracy of `10` meters.
        samples        : 3, // How many location samples to attempt.
      });
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        this.getCurrentPosition().then((location: any) => {
          resolve(this.getPositionFormated(location));
        });
      }, 3000);
    });
  }

  forceRecuperationPosition(observer) {
    BackgroundGeolocation.getCurrentPosition({
      samples   : 1,
      maximumAge: 600000,
      persist   : true,
    }).then((location) => {
      if (this.enabled) {
        const position = this.getPositionFormated(location);
        observer.next(position);
      } else {
        observer.complete();
      }
    });
  }

  getPositionFormated(location: any): Position {
    const position: Position = {
      coords: {
        latitude        : location.coords.latitude,
        longitude       : location.coords.longitude,
        accuracy        : location.coords.accuracy,
        altitude        : location.coords.altitude ?? null,
        speed           : location.coords.speed ?? null,
        heading         : null,
        altitudeAccuracy: null,
      },
      timestamp: new Date(location.timestamp).getTime(),
    };
    return position;
  }

  private updateDriverGeolocationFlag(): void {
    this.cm.getConcept('C_Gds_EvenementChauffeur').paramsToSend.flagDispo = this.enabled
      ? '1'
      : '0';

    this.gds.post('divers', '/gdsv3/chauffeur-compte', {
      CHU_FLAG_DISPO: this.enabled ? '1' : '0',
    });
  }
}
