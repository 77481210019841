<ion-row class="main-row ion-justify-content-around ion-align-items-center">
  <div>
   <remix-icon [icon]="icon" color="d2"></remix-icon>
  </div>

  <ion-col>
    <ion-label>
      <ion-text>
        <h3 class="name">{{ lieu.LIE_LIBELLE | translate }}</h3>
      </ion-text>
      <div class="lieu-infos" >
        <p>
          {{ lieu.LIE_FORMATED }}
        </p>
      </div>
    </ion-label>
  </ion-col>
</ion-row>