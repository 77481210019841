<div class="quoted-vehicle-list">
  <div
    class="quoted-vehicle"
    *ngFor="let quotedVehicle of quotedVehicles"
    (click)="selectQuotedVehicle(quotedVehicle.value)"
  >
    <div
      class="vehicle-details"
      [ngClass]="{ selected: selected?.VOI_ID === quotedVehicle.value.VOI_ID }"
    >
      <span>{{ quotedVehicle.vehiclePlate }}</span>
      <span>({{ quotedVehicle.value.HT }} € {{ 'HT' | translate }}/Km)</span>
    </div>
    <tabler-icon
      *ngIf="selected?.VOI_ID === quotedVehicle.value.VOI_ID"
      icon="check"
      size="md"
      color="success"
    ></tabler-icon>
  </div>
</div>
