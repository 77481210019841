<ion-modal
  #actionsModal
  [keepContentsMounted]="true"
  [isOpen]="showModal"
  [canDismiss]="!cooldownActive"
  (didDismiss)="dismiss()"
  [initialBreakpoint]="1"
  [breakpoints]="[0, 1]"
  mode="ios"
  class="geolocation-modal"
>
  <ng-template>
    <div class="geolocation-modal-container flex flex-col items-center gap-xxl">
      <div class="geolocation-image">
        <img src="../../../../assets/images/geolocation-driver.svg" />
      </div>
      <div class="flex flex-col text-center gap">
        <h2 class="head-title dark">
          {{ geolocationMessage[requestType].title | translate }}
        </h2>
        <p class="text-lg font-medium">
          {{ geolocationMessage[requestType].description | translate }}
        </p>
        <a
          href="https://way-partner.com/politique-de-confidentialite/"
          target="_blank"
          class="privacy-link"
        >
          {{ 'Voir les règles de confidentialité' | translate }}
        </a>
      </div>
      <div class="flex flex-col gap w-full">
        <ion-button
          class="font-semibold text-md"
          fill="filled"
          (click)="handleActivateGeolocation()"
          >{{ 'Activer la géolocalisation' | translate }}</ion-button
        >
        <ion-button
          [disabled]="cooldownActive"
          class="cancel-button font-semibold text-md"
          fill="filled"
          (click)="dismiss()"
          ><span class="mr-xs">{{ 'Annuler' }}</span>
          <span *ngIf="cooldownActive">(0:0{{ cooldownTime }})</span></ion-button
        >
      </div>
    </div>
  </ng-template>
</ion-modal>
