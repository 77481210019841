<ion-list mode="ios" *ngFor="let group of items">
  <ion-menu-toggle *ngFor="let page of group" auto-hide="false">
    <ion-item
      *ngIf="page.url"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="'selected'"
      [routerLink]="page.url"
      detail="false"
      lines="none"
      routerDirection="root"
    >
      <div class="menu-icon">
        <remix-icon [icon]="page.icon" class="size-lg" slot="start"></remix-icon>
        <ion-badge color="danger" *ngIf="page.liste && page.liste.length > 0">{{
          page.liste.length
        }}</ion-badge>
        <ion-label>{{ page.title | translate }}</ion-label>
      </div>
    </ion-item>
    <ion-item
      (click)="page.click()"
      *ngIf="page.click"
      button
      detail="false"
      lines="none"
      routerDirection="root"
    >
      <remix-icon [icon]="page.icon" class="size-lg" slot="start"></remix-icon>
      <ion-label>{{ page.title | translate }}</ion-label>
    </ion-item>
  </ion-menu-toggle>
</ion-list>
