import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SpeechRecognition } from '@capacitor-community/speech-recognition';
import { Platform, ToastController, ToastOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Jaf } from '@way-lib/common/services/jaf/jaf';
import { Subscription } from 'rxjs';

enum SpeechRecognitionError {
  MISSING_PERMISSIONS = 'MISSING_PERMISSIONS',
}

export type TranscriptionEvent = {
  text: string;
  type: 'ios' | 'android' | 'web';
};

@Component({
  selector   : 'voice-input',
  templateUrl: './voice-input.component.html',
  styleUrls  : ['./voice-input.component.scss'],
})
export class VoiceInputComponent implements OnInit, OnDestroy {
  @Output() emitSpeech = new EventEmitter<TranscriptionEvent>();

  @Output() emitToggleRecording = new EventEmitter<boolean>();

  public recording = false;

  private isWeb: boolean;

  private webSpeechRecognition: any;

  private speechRecognitionSubscription: Subscription;

  constructor(
    private cdf: ChangeDetectorRef,
    private translate: TranslateService,
    private toastController: ToastController,
    private platform: Platform,
  ) {}

  ngOnInit(): void {
    this.isWeb = this.platform.is('desktop') || this.platform.is('mobileweb');

    if (this.isWeb) {
      const SpeechRecognitionWeb =
        (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

      if (!SpeechRecognitionWeb) return;

      this.webSpeechRecognition = new SpeechRecognitionWeb();
      this.addWebSpeechListeners();

      return;
    }

    SpeechRecognition.addListener('partialResults', (data: any) => {
      if (!data.matches || !data.matches.length) return;

      this.emitSpeech.emit({
        text: data.matches[0],
        type: 'ios',
      });
    });
  }

  public async toggleRecognition(): Promise<void> {
    if (this.recording) {
      this.stopRecognition();
      return;
    }

    this.startRecognition();
  }

  public async startRecognition(): Promise<void> {
    if (this.isWeb) {
      this.startWebRecognition();
      return;
    }

    this.startMobileRecognition();
  }

  public stopRecognition(): Promise<void> {
    if (this.isWeb) {
      this.stopWebRecognition();
      return;
    }

    this.stopMobileRecognition();
  }

  private async startMobileRecognition(): Promise<void> {
    try {
      await this.checkMobilePermissions();

      this.toggleRecording(true);

      SpeechRecognition.start({
        language      : 'fr-FR',
        maxResults    : 2,
        partialResults: true,
        popup         : true,
      })
        .then(({ matches }) => {
          if (!matches || !matches.length) return;

          this.emitSpeech.emit({
            text: matches[0],
            type: 'android',
          });
        })
        .catch((err) => {
          this.logger(`[START METHOD] ERROR: ${JSON.stringify(err)}`);
        })
        .finally(() => {
          if (!this.platform.is('ios')) {
            this.toggleRecording(false);
          }
        });
    } catch (error: any) {
      console.error('startRecognition error:', error);
      this.handleError(error);
      this.toggleRecording(false);
    }
  }

  private async startWebRecognition(): Promise<void> {
    this.toggleRecording(true);

    try {
      await this.checkWebPermission();

      if (!this.webSpeechRecognition) {
        throw new Error('Web Speech Recognition is not supported in this browser');
      }

      this.webSpeechRecognition.lang = Jaf.LAN_CODE || 'fr-FR';
      this.webSpeechRecognition.start();
    } catch (error: any) {
      console.error('startWebRecognition error:', error);
      this.handleError(error);
      this.toggleRecording(false);
    }
  }

  private async checkMobilePermissions(): Promise<void> {
    // eslint-disable-next-line no-useless-catch
    try {
      const { speechRecognition: permission } = await SpeechRecognition.checkPermissions();

      if (permission === 'granted') {
        return;
      }

      const { speechRecognition: newPermission } = await SpeechRecognition.requestPermissions();

      if (newPermission === 'granted') {
        return;
      }

      throw new Error(SpeechRecognitionError.MISSING_PERMISSIONS);
    } catch (error) {
      throw error;
    }
  }

  private async checkWebPermission(): Promise<void> {
    return new Promise((resolve, reject) => {
      navigator.permissions.query({ name: 'microphone' as any }).then((permission) => {
        const hasPermission = permission.state === 'granted';

        if (hasPermission) {
          return resolve();
        }

        return navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(() => resolve())
          .catch(() => {
            reject(SpeechRecognitionError.MISSING_PERMISSIONS);
          });
      });
    });
  }

  private stopMobileRecognition(): void {
    SpeechRecognition.stop();

    this.toggleRecording(false);
  }

  private stopWebRecognition(): void {
    this.toggleRecording(false);
    this.webSpeechRecognition?.stop();
  }

  private toggleRecording(active: boolean): void {
    this.recording = active;
    this.emitToggleRecording.emit(active);
    this.cdf.detectChanges();
  }

  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      position   : 'top',
      translucent: false,
      message    : this.translate.instant('Une erreur est survenue, veuillez réessayer'),
      duration   : 3000,
      color      : 'danger',
      buttons    : [
        {
          icon: 'close',
          role: 'cancel',
        },
      ],
    };

    if (error === SpeechRecognitionError.MISSING_PERMISSIONS) {
      toastOptions.duration = 6000;
      toastOptions.message  = this.translate.instant(
        "Pour utiliser la dictée vocale des missions, veuillez autoriser l'accès au microphone pour Way-partner",
      );
    }

    this.toastController.create(toastOptions).then((toast) => toast.present());
  }

  private addWebSpeechListeners(): void {
    if (!this.webSpeechRecognition) return;

    this.webSpeechRecognition.onresult = (event: any) => {
      const results = Array.from(event.results).map((result: any) => result[0].transcript);

      this.emitSpeech.emit({
        text: results[0],
        type: 'web',
      });

      this.toggleRecording(false);
    };

    this.webSpeechRecognition.onerror = (event: any) => {
      console.error('Web Speech Recognition error:', event);
      this.toggleRecording(false);
    };
  }

  private logger(message: string) {
    console.log(`[DEV]: ${message}`);
  }

  ngOnDestroy(): void {
    if (!this.isWeb) {
      this.speechRecognitionSubscription?.unsubscribe();
    }
  }
}
