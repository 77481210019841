<div class="progress-container">
  <svg class="progress-ring" width="48" height="48">
    <circle
      class="progress-ring__background {{mode}}"
      stroke-width="8"
      fill="transparent"
      r="20"
      cx="24"
      cy="24"
    ></circle>
    <circle
      class="progress-ring__circle {{mode}}"
      stroke-width="8"
      fill="transparent"
      r="20"
      cx="24"
      cy="24"
      stroke-linecap="round"
      [attr.stroke-dasharray]="strokeDasharray"
      [attr.stroke-dashoffset]="strokeDashoffset"
    ></circle>
  </svg>
  <div class="progress-text {{mode}}">{{ value }}/{{ max }}</div>
</div>