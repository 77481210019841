import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';

export enum Theme {
  Light = 'light',
  Dark = 'dark',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  selectedTheme: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(Theme.Light);

  preferredTheme: Theme;

  automaticTheme: boolean;

  constructor(private storage: Storage) {}

  initializeTheme() {
    const prefersDark   = window.matchMedia('(prefers-color-scheme: dark)');
    this.preferredTheme = prefersDark.matches ? Theme.Dark : Theme.Light;
    this.storage.get('theme').then((theme) => {
      if (theme) {
        this.applyTheme(theme === Theme.Dark ? Theme.Dark : Theme.Light);
      } else {
        this.applyTheme(prefersDark.matches ? Theme.Dark : Theme.Light);
      }
    });

    this.storage.get('automaticTheme').then((automaticTheme) => {
      this.automaticTheme = automaticTheme;
    });

    prefersDark.addEventListener('change', (mediaQuery) => {
      this.preferredTheme = mediaQuery.matches ? Theme.Dark : Theme.Light;

      if (this.automaticTheme) {
        this.applyTheme(this.preferredTheme);
        return;
      }

      this.storage.get('theme').then((theme) => {
        if (!theme) {
          this.applyTheme(mediaQuery.matches ? Theme.Dark : Theme.Light);
        }
      });
    });
  }

  toggleTheme(event: CustomEvent) {
    this.applyTheme(event.detail.checked ? Theme.Dark : Theme.Light);
  }

  toggleAutomaticTheme(event: CustomEvent) {
    this.automaticTheme = event.detail.checked;
    const prefersDark   = window.matchMedia('(prefers-color-scheme: dark)');
    this.preferredTheme = prefersDark.matches ? Theme.Dark : Theme.Light;
    this.applyTheme(this.preferredTheme);
    this.saveAutomaticPreference();
  }

  applyTheme(theme: Theme) {
    this.selectedTheme.next(theme);
    this.toggleDarkTheme(theme === Theme.Dark);
    this.saveThemePreference();
  }

  toggleDarkTheme(shouldAdd: boolean) {
    document.body.classList.toggle('dark', shouldAdd);
  }

  saveThemePreference() {
    this.storage.set('theme', this.selectedTheme.value);
  }

  saveAutomaticPreference() {
    this.storage.set('automaticTheme', this.automaticTheme);
  }
}
