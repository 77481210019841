<ion-row class="main-row ion-justify-content-around ion-align-items-center">
  <div>
    <tabler-icon [icon]="place.icon" color="d2" size="sm"></tabler-icon>
  </div>

  <ion-col>
    <ion-label>
      <ion-text>
        <h3 class="text-lg font-semibold">{{ place.LIE_LIBELLE | translate }}</h3>
      </ion-text>
      <div class="lieu-infos">
        <p class="text-md font-medium">
          {{ place.LIE_FORMATED }}
        </p>
      </div>
    </ion-label>
  </ion-col>
</ion-row>
