import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SubscriptionsService } from '@way-lib/common/services/subscriptions.service';
import { MenuService } from '@way-partner/services/menu.service';
import { ConceptManager } from '@way-lib/common/services/jaf/concept-manager';
import { CGdsInstallationRow } from '@way-lib/models/Gds/CGdsInstallationRow';
import { Subscription } from 'rxjs';
import { IonMenu } from '@ionic/angular'

type NavigationItem = {
  title: string;
  url?: any[] | string | null | undefined;
  click?: any;
  icon: string;
  liste?: any;
};

@Component({
  selector   : 'way-partner-menu',
  templateUrl: './menu.component.html',
  styleUrls  : ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() disabled: any;

  @Input() gdsName: string;

  @Input() version: string;

  @ViewChild('menu', { static: true })
  menu: IonMenu;

  navigationItems: NavigationItem[];

  routeSubscription: Subscription;

  subscriptions: Subscription[] = [];

  disabledMenu: boolean = false;

  installation: CGdsInstallationRow;

  constructor(
    private cm: ConceptManager,
    private router: Router,
    private menuService: MenuService,
    public subscriptionService: SubscriptionsService,
  ) {}

  ngOnInit(): void {
    this.navigationItems = this.menuService.main;

    this.cm.setOnLoad(() => {
      this.installation = this.cm.getInstallationPrincipal();
    });

    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.disabledMenu = event.url === '/signup' || event.url === '/signin';
        }
      }),
      this.subscriptionService.subscriptions$.subscribe((subscription) => {
        this.disabledMenu = !this.subscriptionService.hasActiveSubscription(subscription);
      }),
    );
  }

  public isUnsubscribedPage(page: NavigationItem): boolean {
    if (!page.url || page.url[0] === '/parameters') {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
