<div class="destination-search-container">
  <way-place-autocomplete
    [formGroup]="formGroup"
    (searchChange)="onSearchChange($event)"
    (foundEvents)="onFoundEvents($event)"
    [triggerAPIQuery]="triggerAPIQuery"
    [errorMsg]="errorMsg"
  ></way-place-autocomplete>

  <div class="categories-btn">
    <ion-chip
      *ngFor="let category of categories"
      (click)="selectCategory(category)"
      [ngClass]="{ selected: category === selectedCategory }"
    >
      {{ category | translate }}
    </ion-chip>
  </div>
</div>

<ion-content class="place-list">
  <ion-list (touchmove)="onListScroll($event)">
    <ng-container *ngFor="let place of displayedPlaces">
      <destination-list-item
        *ngIf="displayPlace(place)"
        (click)="onClick(place)"
        [lieu]="place"
      ></destination-list-item>
    </ng-container>
  </ion-list>
</ion-content>
