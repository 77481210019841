import { FormGroup } from '@angular/forms';

export class SignUpForms {
  COMPANY: FormGroup;

  IDENTITY: FormGroup;

  LANG: FormGroup;

  VEHICLE: FormGroup;

  CGU: FormGroup;
}

export enum SignUpTunnelForms {
  COMPANY = 'COMPANY',
  IDENTITY = 'IDENTITY',
  LANG = 'LANG',
  VEHICLE = 'VEHICLE',
  CGU = 'CGU',
}

export enum SignUpTunnelFormsLabel {
  COMPANY = 'Votre entreprise',
  IDENTITY = 'Votre identité',
  LANG = 'Langues',
  VEHICLE = 'Véhicule',
  CGU = "Conditions générales d'utilisation",
}
