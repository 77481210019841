<div class="stepper-container" *ngIf="control">
  <p>{{ label | translate }}</p>
  <div class="stepper-inputs">
    <ion-button (click)="decrement()" [disabled]="control.value === min"
      ><tabler-icon icon="minus" size="md" color="d2"></tabler-icon
    ></ion-button>
    <div class="stepper-value">{{ control.value ?? 0 }}</div>
    <ion-button (click)="increment()"><tabler-icon icon="plus" size="sm"></tabler-icon></ion-button>
  </div>
</div>
