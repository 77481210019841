import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector   : 'stepper-input',
  templateUrl: './stepper-input.component.html',
  styleUrls  : ['./stepper-input.component.scss'],
})
export class StepperInputComponent {
  @Input() control: FormControl;

  @Input() min: number = 0;

  @Input() label: string;

  public increment(): void {
    this.control.setValue(this.control.value + 1);
  }

  public decrement(): void {
    if (this.control.value === this.min) return;
    this.control.setValue(this.control.value - 1);
  }
}
